import {
  // Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  // makeStyles,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";

function Dialogs(props) {
  //   const classes = useStyles();
  const { title, children, open, setOpen, size } = props;

  const Fermers = () => {
    setOpen(!open);
    // setEtatx(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth={size} //lg, md, sm, xs,xl
      // maxWidth="lg"
      sx={{
        padding: 2,
        position: "absolute",
        top: 5,
        // width: "100%",
      }}
      onClose={() => Fermers()}
    >
      <DialogTitle
        sx={{
          bgcolor: "#44CAF5",
        }}
      >
        <Stack direction={"row"} justifyContent="space-between">
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: "1", color: "#FFF" }}
          >
            {title}
          </Typography>

          <IconButton
            sx={{
              borderColor: "#FFF",
              borderWidth: 1,
              borderRadius: 2,
            }}
            onClick={() => Fermers()}
            size="large"
          >
            <Close sx={{ color: "#FFF" }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <h6
        style={{
          color: "#FFF",
        }}
      >
        oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
      </h6>
    </Dialog>
  );
}

export default Dialogs;
