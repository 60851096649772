import requests from "./AxiosInterceptors";

const ProductServices = {
  getProduct: async (id) => {
    return requests.get("/entreprise/produit/"+id);
  },
  oneProduct: async (id) => {
    return requests.get("/DetailProduct/"+id);
  },
  deleteProduct: async (entreprise,id) => {
    return requests.delete(`/entreprise/produit/${entreprise}/${id}`);
  },
  updateStatus: async (body) => {
    return requests.put(`/change-produit-status`, body);
  },
  createProduct: async (body, entreprise) => {
    return requests.post("/entreprise/produit/" + entreprise, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  addSubCategory: async (body) => {
    return requests.post("/createsouscat", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateProduct: async (body, entreprise, id) => {
    return requests.post(`/entreprise/produit/${entreprise}/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateSubCategory: async (body) => {
    return requests.post("/updatesouscatgory", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default ProductServices;
