import React from "react";

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";

import { Container, Row, Col, Card, CardBody, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Inputs from "../../controller/Inputs";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/form/TextInput";
import Footer from "../../components/auth/Footer";
import Header from "../../components/auth/Header";
import requests from "../../services/AxiosInterceptors";
import { notifyError, notifySuccess } from "../../utils/toast";
import Boutons from "../../controller/Buttonsx";
import { Typography } from "antd";
import Button from "../../components/form/Button";
import { documentTitle } from "../../utils/documentTitle";

const ValidationEmail = () => {
  documentTitle("Code de validation")
  const nav = useNavigate();
  const [disable, setDisable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Veuillez entrer le code"),
    }),
    onSubmit: (value) => {
      // console.log(value);
      setIsLoading(true);
      requests
        .post("/putOtp", value)
        .then((e) => {
          notifySuccess(e.data.message);
          nav("/change-passeword", {replace:true});
        })
        .catch((error) => {
          setIsLoading(false);
          notifyError(error.response.data.message);
        });
    },
  });

  const ReEnvoiePassword = () => {
    setDisable(true);
    let emailx = localStorage.getItem("email");
    const value = {
      email: emailx,
    };
    console.log(value);
    requests
      .post("/ask_otp", value)
      .then((e) => {
        notifySuccess(e.data.message);
        setDisable(false);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
        setDisable(false);
      });
  };

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="bg-overlay"></div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <Header />
                      <div className="alert alert-warning alert-dismissible">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                        Entrez <b>le code de validation</b> qui vous a été
                        envoie
                      </div>

                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <div className="mt-4">
                              <TextInput
                                type="code"
                                className="form-control"
                                id={"otp"}
                                label="Code de réinitialisation"
                                placeholder="Code de réinitialisation"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.otp}
                                error={validation.errors.otp}
                                invalid={
                                  validation.touched.otp &&
                                  validation.errors.otp
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="d-grid mt-4">
                              <Button
                                title="Envoyer"
                                isLoading={isLoading}
                                type="submit"
                              />
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
                <p className="text-white-50 text-center">
                  N'avez-vous pas réçu de code ?
                  <span
                    role="button"
                    onClick={ReEnvoiePassword}
                    className="fw-medium text-primary cursor-pointer"
                  >
                    {" "}
                    Rénvoyez-le{" "}
                    {disable && (
                      <div
                        class="spinner-border text-primary spinner-border-sm ml-4"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>{" "}
                </p>
                <p className="text-white-50 text-center">
                  Avez-vous déjà un compte ?
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Connectez-vous{" "}
                  </Link>{" "}
                </p>
                <Footer />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ValidationEmail;
