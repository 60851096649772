import React from "react";
import DataTableCom from "../../controller/DataTableCom";
import Inputs from "../../controller/Inputs";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import useAsync from "../../Hooks/useAsync";
import OrderServices from "../../services/OrderServices";
import { documentTitle } from "../../utils/documentTitle";
import GlobalContext from "../../contexts/globalContext";
import ModalDelete from "../../components/modals/SwalDelete";
import StatusTableInput from "../../components/form/StatusTableInput";
import ChangeOrderStatus from "../../components/form/ChangeOrderStatus";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import MarqueForm from "../../components/marques/MarqueForm";
import {FaFileInvoice} from 'react-icons/fa'
import MainModal from "../../components/modals/MainModal";
import { dateFormat } from "../../utils/date";
import Button from "../../components/form/Button";

function Commande() {
  documentTitle("Commandes");

  const { data: orders, loading } = useAsync(OrderServices.getOrders);

 
    const column = [
      {
        name: (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Date
          </Typography>
        ),
        selector: (row) => dateFormat(row.created_at),
        sortable: true,
      },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Nom du receveur
        </Typography>
      ),
      selector: (row) => row.billing_fullname,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Sous-total
        </Typography>
      ),
      selector: (row) => row.total + " " + row.billing_currency,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Frais d'expédition
        </Typography>
      ),
      selector: (row) => row.free_shipping + " " + row.billing_currency,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Total
        </Typography>
      ),
      selector: (row) => row.grand_total + " " + row.billing_currency,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Satutus
        </Typography>
      ),
      cell: (row) => {
        // console.log(row.image_cat);`http://nunuaapi.afianetrdc.com/v2/upload/${row.image_cat}}`
        // let img = "http://nunuaapi.afianetrdc.com/v2/upload/" + row.image_cat;
        return (
          <Stack direction={"row"} spacing={1}>
                <ChangeOrderStatus status={row.status} id={row.id}/>
          </Stack>
        );
      },
    },


    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
            textAlign:'center'
          }}
        >
          Actions
        </Typography>
      ),
      cell: (row) => (
        <Stack style={{justifyContent:'center', textAlign:'center'}} direction={"row"} spacing={1}>
          <IconButton>
            <FaFileInvoice color="info" />
          </IconButton>
        </Stack>
      ),
    },
  ];
  return (
    <div className="page-content">
      <div class="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
        <h4 className="text-black font-15">Liste des commandes</h4>
      </div>
      <div
        style={{
          padding: 20,
          background: "white",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableCom loading={loading} data={orders} columns={column} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Commande;
