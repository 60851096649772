import requests from "./AxiosInterceptors";

const OrderServices = {
  getOrders: async () => {
    return requests.get("/admin/orders");
  },
  getShopOrders: async (id) => {
    return requests.get(`/entreprise/orders/${id}`);
  },
  deleteMarque: async (id) => {
    return requests.delete("/marque/" + id);
  },
  createMarque: async (body) => {
    return requests.post("/marque", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  changeStatus: async (body) => {
    return requests.put("/change-order-status", body);
  },
};

export default OrderServices;
