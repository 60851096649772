import React from "react";

const CheckBox = ({ label, id, name, value, onChange, checked }) => {
  return (
    <div style={{ marginRight: 10 }} className="form-check mb-2 ml-2">
      <input
        className="form-check-input"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        value={value}
        id={id}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
