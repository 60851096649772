import React, { useContext } from "react";
import OrderServices from "../../services/OrderServices";
import { notifySuccess, notifyError } from "../../utils/toast";
import GlobalContext from "../../contexts/globalContext";
const ChangeOrderStatus = ({ status, id }) => {
  const { setRefresh } = useContext(GlobalContext);
  const changeStatus = (e) => {
    OrderServices.changeStatus({
      status: e.target.value,
      id_commande: id,
    })
      .then((response) => {
        setRefresh();
        notifySuccess(response.data.message);
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
      });
  };

  return (
    <div className="col-12">
      <select
        value={status}
        onChange={changeStatus}
        className="form-select"
        id="inlineFormSelectPref"
      >
        <option defaultValue>Choose...</option>
        <option value="pending">En attente</option>
        <option value="processing">Traitement</option>
        <option value="completed">Complétée</option>
        <option value="delivered">Livrée</option>
      </select>
    </div>
  );
};

export default ChangeOrderStatus;
