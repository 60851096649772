import { notifyError, notifySuccess } from "../utils/toast";
import React from "react";
import GlobalContext from "../contexts/globalContext";
import MesureServices from "../services/MesureServices";
import EngagementServices from "../services/EngagementServices";
const useEngagementSubmit = (selectedId) => {
  const {
    isLoading,
    setIsLoading,
    companyInfo,
    searchedUser,
    permissions,
    setSelectedRole,
    selectedRole,
    setPermissions,
    closeModal,
    setRefresh,
  } = React.useContext(GlobalContext);

  const submit = async () => {
    if (!searchedUser) {
      notifyError("Veuillez sélélctionner un utilisateur");
      return;
    }
    if (permissions.length < 2) {
      notifyError("Veuillez cocher au moins deux permissions");
      return;
    }
    if (!selectedRole) {
      notifyError("Veuillez sélélctionner un role");
      return;
    }
    let data = {
      id_user: searchedUser,
      id_role: selectedRole,
      id_entrep: companyInfo.id_entrep,
      permissions: permissions,
    };
    if (selectedId) {
      setIsLoading(true);
      // MesureServices.updateMesure(data, companyInfo.id_entrep, selectedId.id)
      //   .then((res) => {
      //     setRefresh();
      //     closeModal();
      //     notifySuccess(res.data.message);
      //     setIsLoading(false);
      //   })
      //   .catch((err) => {
      //     notifyError(err ? err.response.data.message : err.message);
      //     setIsLoading(false);
      //   });
    } else {
      setIsLoading(true);
      EngagementServices.createFromCloud(data)
        .then((res) => {
          setIsLoading(false);
          setRefresh();
          closeModal();
          notifySuccess(res.data.message);
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
          setIsLoading(false);
        });
    }
  };

  return {
    submit,
    isLoading,
    setIsLoading,
  };
};

export default useEngagementSubmit;
