import React from "react";
import Button from "../../components/form/Button";
import { documentTitle } from "../../utils/documentTitle";
import DataTableCom from "../../controller/DataTableCom";
import { Typography, Stack, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import EmployeeForm from "../../components/employee/EmployeeForm";
import { dateFormat } from "../../utils/date";
import MainDrawer from "../../components/modals/MainDrawer";
import RoleServices from "../../services/RoleServices";
import GlobalContext from "../../contexts/globalContext";
import EngagementServices from "../../services/EngagementServices";
import PermissionServices from "../../services/PermissionServices";
import useAsync from "../../Hooks/useAsync";
function Teams() {
  documentTitle("Liste des employés");
  const {
    modalIsOpen,
    closeModal,
    toggleModal,
    isUpdate,
    companyInfo,
    handleUpdate,
    handleDelete
  } = React.useContext(GlobalContext);
  const { data: permissions } = useAsync(PermissionServices.getPermissions);
  const { data: roles } = useAsync(() => RoleServices.getRoles(companyInfo.id_entrep));
  const { data, loading } = useAsync(() =>
    EngagementServices.getTeam(companyInfo.id_entrep)
  );
  const column = [
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Full name
        </Typography>
      ),
      selector: (row) => row?.user?.full_name,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Email
        </Typography>
      ),
      selector: (row) => row?.user?.email,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Role
        </Typography>
      ),
      selector: (row) => row?.role?.name,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Image
        </Typography>
      ),
      selector: (row) => (
        <img
          src={row?.user?.provider ? row?.user?.profil : process.env.REACT_APP_URL_IMAGE +'user/' + row?.user?.profil}
          className="rounded-3"
          style={{ width: 30 }}
        />
      ),
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Date d'engagement
        </Typography>
      ),
      selector: (row) => dateFormat(row.created_at),
      sortable: true,
    },
  
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Action
        </Typography>
      ),
      button: true,
      cell: (row) => (
        <Stack direction={"row"} spacing={1}>
          <IconButton onClick={() => handleUpdate(row)}>
            <Edit color="info" />
          </IconButton>
          <IconButton onClick={() => handleDelete(row)}>
            <Delete color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];
  return (
    <div className="page-content">
      <div class="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
        <h4 className="text-black font-15">Liste des employés</h4>
        <Button onClick={toggleModal} title={"Engager un agent"} />
      </div>
      <div
        style={{
          padding: 20,
          background: "white",
        }}
      >
         <DataTableCom loading={loading} data={data} columns={column} />
        <MainDrawer
          size="xl"
          title={isUpdate ? "Modifier un agent" : "Ajouter un agent"}
          open={modalIsOpen}
          setOpen={closeModal}
        >
          <EmployeeForm permissions={permissions} roles={roles} />
        </MainDrawer>
      </div>
    </div>
  );
}

export default Teams;
