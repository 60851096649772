import { Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import useAsync from "../../Hooks/useAsync";
import { Description, EmojiObjects, Money } from "@mui/icons-material";
import Comboboxs from "../../controller/ComboBox";
import UploadAndDisplayImage from "../../controller/UploadImage";
import { TreeSelect } from "antd";
import Colors from "../../controller/Colors";
import SelectTwo from "../form/SelectTwo";
import UploaderMu from "../../controller/UploadImageAnted";
import EditorText from "../form/EditorText";
import Select from "react-select";
import classnames from "classnames";
import GlobalContext from "../../contexts/globalContext";
import TextInputGroup from "../form/TextInputGroup";
import TextInput from "../form/TextInput";
import CustomSwitch from "../form/CustomSwitch";
import TextArea from "../form/TextArea";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Progress,
  NavLink,
  NavItem,
} from "reactstrap";
import Button from "../form/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import CathegorieService from "../../services/CategorieServices";
import MarqueServices from "../../services/MarqueServices";
import useProductSubmit from "../../Hooks/userProductSubmit";
import MesureServices from "../../services/MesureServices";

function FormProduct() {
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabwiz, setoggleTabwiz] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedStepswiz, setpassedStepswiz] = useState([1]);
  const {
    images,
    setImages,
    toggleCombination,
    isCombination,
    unit,
    setUnit,
    companyInfo,
    selectedId,
    isUpdate,
    category,
    setCategory,
    brand,
    setBrand,
  } = React.useContext(GlobalContext);

  const { data: mesures } = useAsync(() =>
    MesureServices.getMesures(companyInfo.id_entrep)
  );
  const { data: marques } = useAsync(MarqueServices.allMarque);
  const { data: categories } = useAsync(CathegorieService.categoryTree);
  const { isLoading, submit } = useProductSubmit(selectedId);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function toggleTabwiz(tab) {
    if (activeTabwiz !== tab) {
      var modifiedSteps = [...passedStepswiz, tab];
      if (tab >= 1 && tab <= 4) {
        setoggleTabwiz(tab);
        setpassedStepswiz(modifiedSteps);
      }
    }
  }
  const renderCategories = (categories) => {
    let myCategories = [];
    for (let category of categories) {
      myCategories.push({
        title: category.name,
        value: category.id,
        children:
          category.children.length > 0 && renderCategories(category.children),
      });
    }

    return myCategories;
  };

  const [selectedGroup, setselectedGroup] = useState(null);
  const [errorCategory, setErrorCategory] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorBrand, setErrorBrand] = useState(null);
  const [errorUnit, setErrorUnit] = useState(null);
  const [mesureListe, setMesureListe] = useState([]);
  const formSubmit = React.useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    formSubmit.current.click();
  };
  React.useEffect(() => {
    let res1 = mesures.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
    setMesureListe(res1);
  }, []);

  // const { getFile } = UploadImageAnted();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: selectedId ? selectedId?.name : "",
      originalPrice: selectedId ? selectedId?.price : "",
      price: selectedId ? selectedId?.price_red : "",
      quantity: selectedId ? selectedId?.qte : "",
      description: selectedId ? selectedId?.description : "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Ce champs est requis"),
      originalPrice: Yup.string().required("Ce champs est requis"),
      price: Yup.string().required("Ce champs est requis"),
      quantity: Yup.string().required("Ce champs est requis"),
      description: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      let valide = true;
      if (!category) {
        setErrorCategory("Veuillez séléctionner une catégorie");
        valide = false;
      }
      if (!unit) {
        setErrorUnit("Veuillez séléctionner une unité de mésure");
        valide = false;
      }
      if (!brand) {
        setErrorBrand("Veuillez séléctionner une marque");
        valide = false;
      }

      if (valide) {
        setErrorBrand(null);
        setErrorCategory(null);
        setErrorUnit(null);
        if (activeTabwiz < 2) {
          toggleTabwiz(activeTabwiz + 1);
        } else {
          submit(values);
        }
      }
    },
  });

  return (
    <Col lg="12">
      <Card>
        <CardBody>
          <div id="progrss-wizard" className="twitter-bs-wizard">
            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
              <NavItem
                className={classnames({
                  active: activeTabwiz === 1,
                })}
              >
                <NavLink
                  className={classnames({
                    active: activeTabwiz === 1,
                  })}
                  // onClick={() => {
                  //   toggleTabwiz(1);
                  // }}
                >
                  <span className="step-number">01</span>
                  <span
                    role="button"
                    className="step-title"
                    style={{ paddingLeft: "10px" }}
                  >
                    Informations du produit
                  </span>
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames({
                  active: activeTabwiz === 2,
                })}
              >
                <NavLink
                  className={classnames({
                    active: activeTabwiz === 2,
                  })}
                  // onClick={() => {
                  //   toggleTabwiz(2);
                  // }}
                >
                  <span className="step-number">02</span>
                  <span
                    role="button"
                    className="step-title"
                    style={{ paddingLeft: "10px" }}
                  >
                    Images du produit
                  </span>
                </NavLink>
              </NavItem>
            </ul>
            <div id="bar" className="mt-4">
              <div className="mb-4">
                <Progress
                  value={50 * activeTabwiz}
                  color="#44cef5"
                  animated
                ></Progress>
              </div>
            </div>

            <TabContent
              activeTab={activeTabwiz}
              className="twitter-bs-wizard-tab-content"
            >
              <TabPane tabId={1}>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="row">
                    <div className="form-group">
                      <TextInput
                        label="Nom du produit"
                        placeholder="Nom du produit"
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                        error={validation.errors.name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      {/* <EditorText
                        label="Description du produit"
                        id="validationCustom02"
                        placeholder="Description du produit"
                      /> */}
                      <TextArea
                        label="Description"
                        placeholder="Description du produit"
                        name="description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                        error={validation.errors.description}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <TextInputGroup
                        symbole={"$"}
                        type="number"
                        name="originalPrice"
                        label="Prix original"
                        placeholder="Prix original du produit"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.originalPrice}
                        invalid={
                          validation.touched.originalPrice &&
                          validation.errors.originalPrice
                            ? true
                            : false
                        }
                        error={validation.errors.originalPrice}
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <TextInputGroup
                        symbole={"$"}
                        type="number"
                        name="price"
                        label="Prix de vente"
                        placeholder="Prix de vente du produit"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.price}
                        invalid={
                          validation.touched.price && validation.errors.price
                            ? true
                            : false
                        }
                        error={validation.errors.price}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <Label className="form-label">Catégorie</Label>
                      <TreeSelect
                        showSearch
                        value={category}
                        onChange={setCategory}
                        style={{
                          width: "100%",
                        }}
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        treeIcon
                        size="large"
                        placeholder="Select"
                        allowClear
                        treeData={renderCategories(categories)}
                      />
                      {errorCategory ? (
                        <small className="text-danger">{errorCategory}</small>
                      ) : null}
                    </div>
                    <div className="col-lg-6 form-group">
                      <SelectTwo
                        label={"Marque"}
                        onChange={(val) => setBrand(val)}
                        value={brand}
                        data={marques?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                        error={errorBrand}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <SelectTwo
                        label={"Unité de mésure"}
                        onChange={setUnit}
                        value={unit}
                        error={errorUnit}
                        data={mesures?.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <TextInput
                        label="Quantité"
                        type="number"
                        name="quantity"
                        placeholder="Quantité"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.quantity}
                        invalid={
                          validation.touched.quantity &&
                          validation.errors.quantity
                            ? true
                            : false
                        }
                        error={validation.errors.quantity}
                      />
                    </div>
                  </div>
                  <button
                    className="invisible"
                    ref={formSubmit}
                    type="submit"
                  ></button>
                </Form>
              </TabPane>
              <TabPane tabId={2}>
                <div>
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 form-group">
                        <Label>Images du produit</Label>
                        <UploaderMu
                          image={images}
                          setImages={setImages}
                          limite={6}
                        />
                      </div>
                      {/* <div className="col-lg-6 form-group justify-content-between d-flex">
                        <Label>Ce produit a des combinaisons</Label>
                        <CustomSwitch
                          onChange={toggleCombination}
                          checked={isCombination}
                        />
                      </div> */}
                    </div>
                  </Form>
                </div>
              </TabPane>
              {/* <TabPane tabId={3}>
                <div>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="basicpill-namecard-input112">
                            Name on Card
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-namecard-input112"
                            placeholder="Enter Your Name on Card"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label>Credit Card Type</Label>
                          <select className="form-select">
                            <option>Select Card Type</option>
                            <option>American Express</option>
                            <option>Visa</option>
                            <option>MasterCard</option>
                            <option>Discover</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="basicpill-cardno-input122">
                            Credit Card Number
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-cardno-input122"
                            placeholder="Enter Your Card Number"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="basicpill-card-verification-input">
                            Card Verification Number
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-card-verification-input"
                            placeholder="Card Verification Number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="basicpill-expiration-input132">
                            Expiration Date
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-expiration-input132"
                            placeholder="Card Expiration Date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </TabPane>
              <TabPane tabId={4}>
                <div className="row justify-content-center">
                  <Col lg="12">
                    <div className="text-center">
                      <div className="mb-4">
                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                      </div>
                      <div>
                        <h5>Confirm Detail</h5>
                        <p className="text-muted">
                          If several languages coalesce, the grammar of the
                          resulting
                        </p>
                      </div>
                    </div>
                  </Col>
                </div>
              </TabPane> */}
            </TabContent>

            <ul className="pager wizard twitter-bs-wizard-pager-link">
              <li
                className={
                  activeTabwiz === 1
                    ? "previous disabled me-2"
                    : "previous me-2"
                }
              >
                <Link
                  to="#"
                  onClick={() => {
                    toggleTabwiz(activeTabwiz - 1);
                  }}
                >
                  Précedent
                </Link>
              </li>
              {activeTabwiz === 2 ? (
                <li className="next">
                  <Button
                    title="Enregistrer"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                  />
                </li>
              ) : (
                <li className={activeTabwiz === 2 ? "next disabled" : "next"}>
                  <Link to="#" onClick={handleSubmit}>
                    Suivant
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default FormProduct;
