import React, { useContext, useState } from "react";
import UploaderMu from "../../controller/UploadImageAnted";
import Button from "../../components/form/Button";
import {
  Card,
  CardBody,
  CardText,
  Label,
  CardTitle,
  Col,
  Collapse,
  Container,
  CardImg,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { documentTitle } from "../../utils/documentTitle";
import GlobalContext from "../../contexts/globalContext";
import SettingServices from "../../services/SettingServices";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";
import useAsync from "../../Hooks/useAsync";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useSettingSubmit from "../../Hooks/useSettingSubmit";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
function GeneralSettings() {
  const { data: globalSettings } = useAsync(() =>
    SettingServices.getAllSettings()
  );
  const { logo, setLogo, banner, setBanner } = useContext(GlobalContext);
  const [verticalActiveTab, setverticalActiveTab] = useState("1");

  documentTitle("Réglages de l'entreprise");
  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  const { submit, isLoading, saveAbout, saveSocial, politique,setPolitique,condition,setCondition } =
    useSettingSubmit(globalSettings);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: globalSettings ? globalSettings?.app_name : "",
      email: globalSettings ? globalSettings?.email : "",
      phone: globalSettings ? globalSettings?.phone : "",
      adresse: globalSettings ? globalSettings?.adresse : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Ce champs est requis"),
      email: Yup.string()
        .email("Veuillez entrer une adresse email valide")
        .required("Ce champs est requis"),
      phone: Yup.string().required("Ce champs est requis"),
      adresse: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      submit(values);
    },
  });

  React.useEffect(() => {
      setCondition(globalSettings?.condition || "")
      setPolitique(globalSettings?.politique || "")
  }, [globalSettings])

  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      description: globalSettings ? globalSettings?.about_us : "",
      mission: globalSettings ? globalSettings?.mission : "",
      vision: globalSettings ? globalSettings?.vision : "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      saveAbout(values);
    },
  });

  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      facebook: globalSettings ? globalSettings?.facebook : "",
      instagram: globalSettings ? globalSettings?.instagram : "",
      youtube: globalSettings ? globalSettings?.youtube : "",
      linkedin: globalSettings ? globalSettings?.linkedin : "",
    },
    validationSchema: Yup.object({
      facebook: Yup.string().required("Ce champs est requis"),
      instagram: Yup.string().required("Ce champs est requis"),
      youtube: Yup.string().required("Ce champs est requis"),
      linkedin: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      saveSocial(values);
    },
  });
  return (
    <div className="page-content">
      <h4
        style={{
          background: "white",
          padding: 20,
          borderRadius: 5,
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        Réglages de l'application
      </h4>

      <Col xl={12}>
        <Card style={{ height: "100%" }}>
          <CardBody>
            <Row>
              <Col md="2">
                <Nav pills className="flex-column">
                  <div className="justify-content-center align-items-center w-100 text-center">
                    <CardImg
                      src="https://img.freepik.com/vecteurs-libre/vecteur-degrade-logo-colore-oiseau_343694-1365.jpg"
                      alt=""
                      className="rounded avatar-xl"
                    />
                  </div>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-home-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleVertical("1");
                      }}
                    >
                      <i className="dripicons-pencil me-2 align-middle"></i>{" "}
                      Informations
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-profile-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleVertical("2");
                      }}
                    >
                      <i className="dripicons-pencil me-2 align-middle"></i>{" "}
                      Liens sociaux
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-messages-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleVertical("3");
                      }}
                    >
                      <i className="dripicons-pencil me-2 align-middle"></i>{" "}
                      About us
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="10">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'application"}
                            placeholder={"Nom de l'application"}
                            name="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                            error={validation.errors.name}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Email"}
                            placeholder={"Email"}
                            name="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                            error={validation.errors.email}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Phone"}
                            placeholder={"Phone"}
                            name="phone"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                            error={validation.errors.phone}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Adresse"}
                            placeholder={"Adresse"}
                            name="adresse"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.adresse}
                            invalid={
                              validation.touched.adresse &&
                              validation.errors.adresse
                                ? true
                                : false
                            }
                            error={validation.errors.adresse}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3 justify-content-end">
                          <Button
                            type="submit"
                            isLoading={isLoading}
                            title={"Enregistrer"}
                          />
                        </div>
                      </div>
                    </form>
                  </TabPane>
                  <TabPane tabId="2">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation2.handleSubmit();
                        return false;
                      }}
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Facebook"}
                            placeholder={"Facebook"}
                            name="facebook"
                            onChange={validation2.handleChange}
                            onBlur={validation2.handleBlur}
                            value={validation2.values.facebook}
                            invalid={
                              validation2.touched.facebook &&
                              validation2.errors.facebook
                                ? true
                                : false
                            }
                            error={validation2.errors.facebook}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Linkedin"}
                            placeholder={"Linkedin"}
                            name="linkedin"
                            onChange={validation2.handleChange}
                            onBlur={validation2.handleBlur}
                            value={validation2.values.linkedin}
                            invalid={
                              validation2.touched.linkedin &&
                              validation2.errors.linkedin
                                ? true
                                : false
                            }
                            error={validation2.errors.linkedin}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Instagram"}
                            placeholder={"Instagram"}
                            name="instagram"
                            onChange={validation2.handleChange}
                            onBlur={validation2.handleBlur}
                            value={validation2.values.instagram}
                            invalid={
                              validation2.touched.instagram &&
                              validation2.errors.instagram
                                ? true
                                : false
                            }
                            error={validation2.errors.instagram}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Youtube"}
                            placeholder={"Youtube"}
                            name="youtube"
                            onChange={validation2.handleChange}
                            onBlur={validation2.handleBlur}
                            value={validation2.values.youtube}
                            invalid={
                              validation2.touched.youtube &&
                              validation2.errors.youtube
                                ? true
                                : false
                            }
                            error={validation2.errors.youtube}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3 justify-content-end">
                          <Button
                            type="submit"
                            isLoading={isLoading}
                            title={"Enregistrer"}
                          />
                        </div>
                      </div>
                    </form>
                  </TabPane>
                  <TabPane tabId="3">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation1.handleSubmit();
                        return false;
                      }}
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="form-group">
                          <TextInput
                            label={"About us"}
                            placeholder={"About us"}
                            name="description"
                            type={"textarea"}
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.description}
                            invalid={
                              validation1.touched.description &&
                              validation1.errors.description
                                ? true
                                : false
                            }
                            error={validation1.errors.description}
                          />
                        </div>

                        <div className="form-group">
                          <TextInput
                            label={"Mission"}
                            placeholder={"Mission"}
                            name="mission"
                            type={"textarea"}
                            
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.mission}
                            invalid={
                              validation1.touched.mission &&
                              validation1.errors.mission
                                ? true
                                : false
                            }
                            error={validation1.errors.mission}
                          />
                        </div>
                        <div className="form-group">
                          <TextInput
                            label={"Vision"}
                            placeholder={"Vision"}
                            name="vision"
                            type={"textarea"}
                            
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.vision}
                            invalid={
                              validation1.touched.vision &&
                              validation1.errors.vision
                                ? true
                                : false
                            }
                            error={validation1.errors.vision}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label>Politique de confidentialité</label>
                          <CKEditor
                        
                            editor={ClassicEditor}
                            data={politique}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onInit={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              // console.log("Editor is ready to use!", editor);
                              editor.editing.view.change((writer) => {
                              writer.setStyle(
                                  "height",
                                  "500px",
                                  editor.editing.view.document.getRoot()
                              );
                              });
                          }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setPolitique(data)
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label>Confitions d'utilisation</label>
                          <CKEditor
                        
                            editor={ClassicEditor}
                            data={condition}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onInit={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              // console.log("Editor is ready to use!", editor);
                              editor.editing.view.change((writer) => {
                              writer.setStyle(
                                  "height",
                                  "500px",
                                  editor.editing.view.document.getRoot()
                              );
                              });
                          }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setCondition(data)
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3 justify-content-end">
                          <Button
                            isLoading={isLoading}
                            type="submit"
                            title={"Enregistrer"}
                          />
                        </div>
                      </div>
                    </form>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default GeneralSettings;
