import { useNavigate } from "react-router-dom";
import GlobalContext from "../contexts/globalContext";
import { useContext } from "react";
const Logout = () => {
  const navigate = useNavigate();
  const { setCompanyInfo, setUserInfo, setIsAdmin } = useContext(GlobalContext);

  const logoutUser = async () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("company_informations");
    localStorage.removeItem("userToken");
    localStorage.removeItem("isAdmin");
    setCompanyInfo(null);
    setUserInfo(null);
    setIsAdmin(false)
    navigate("/login", { replace: true });
  };

  return {
    logoutUser,
  };
};

export default Logout;
