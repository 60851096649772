import requests from "./AxiosInterceptors";

const MarqueServices = {
  allMarque: async () => {
    return requests.get("/marque");
  },
  deleteMarque: async (id) => {
    return requests.delete("/marque/" + id);
  },
  createMarque: async (body) => {
    return requests.post("/marque", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateMarque: async (body, id) => {
    return requests.post("/marque/" + id, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default MarqueServices;
