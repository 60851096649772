import { notifyError, notifySuccess } from "../utils/toast";
import React, { useState } from "react";
import GlobalContext from "../contexts/globalContext";
import SettingServices from "../services/SettingServices";
const useSettingSubmit = (settings) => {
  const { isLoading, setIsLoading, companyInfo, closeModal, setRefresh } =
    React.useContext(GlobalContext);
  const [politique, setPolitique] = useState("");
  const [condition, setCondition] = useState("");

  const submit = async (values) => {
    setIsLoading(true);
    SettingServices.saveSettings({
      email: values.email,
      phone: values.phone,
      adresse: values.adresse,
      app_name: values.name,
      id: settings?.id ? settings?.id : "",
    })
      .then((response) => {
        setRefresh();
        notifySuccess(response.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyError(err ? err.response.data.message : err.message);
      });
  };

  const saveAbout = async (values) => {
    if (!politique) {
      notifyError("Veuillez saisir la politique de confidentialité");
      return;
    }
    if (!condition) {
      notifyError("Veuillez saisir les conditions d'utilisation");
      return;
    }
    setIsLoading(true);
    SettingServices.saveAbout({
      about_us: values.description,
      mission: values.mission,
      vision: values.vision,
      condition:condition,
      politique:politique
    })
      .then((response) => {
        setRefresh();
        notifySuccess(response.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyError(err ? err.response.data.message : err.message);
      });
  };
  const saveSocial = async (values) => {
    setIsLoading(true);
    SettingServices.saveSocial({
      facebook: values.facebook,
      linkedin: values.linkedin,
      youtube: values.youtube,
      instagram: values.instagram,
      id: settings?.id,
    })
      .then((response) => {
        setRefresh();
        notifySuccess(response.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyError(err ? err.response.data.message : err.message);
      });
  };

  return {
    submit,
    saveAbout,
    saveSocial,
    isLoading,
    setIsLoading,
    politique,
    setPolitique,
    condition,
    setCondition,
  };
};

export default useSettingSubmit;
