import AdminServices from "../services/AdminServices";
import { notifyError, notifySuccess } from "../utils/toast";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "../contexts/globalContext";

const useAdminSubmit = () => {
  const { isLoading, setIsLoading, setUserInfo } = React.useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();

  const submit = async ({ username, email, password }) => {
    // console.log(location);
    setIsLoading(true);
    try {
      if (location.pathname === "/login") {
        const res = await AdminServices.loginAdmin({
          email: email,
          pswd: password,
        });

        // console.log(res)
        if (res.status === 200) {
          setUserInfo(res?.data?.data)
          localStorage.setItem("authUser", JSON.stringify(res?.data?.data));
          localStorage.setItem("userToken", JSON.stringify(res?.data?.token));
          navigate("/", { replace: true });
          //notifySuccess("Login successfully");
        } else {
          notifyError(res.data.message);
        }
        setIsLoading(false);
      } else if (location.pathname === "/recover-password") {
        AdminServices.recoverPassword({ email: email })
          .then((res) => {
            if (res.status === 200) {
              notifySuccess(res.data.message);
              navigate("/valiadtion-code", { replace: true });
              setIsLoading(false);
              localStorage.setItem("email", email);
            } else {
              notifyError(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            notifyError(error.response.data.message);
            setIsLoading(false);
          });
      }
    } catch (err) {
      notifyError(err ? err.response.data.message : err.message);
      setIsLoading(false);
    }
  };

  const editProfile = async (data) => {
      console.log(data)
  }

  return {
    submit,
    isLoading,
    editProfile
  };
};

export default useAdminSubmit;
