import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import DataTableCom from "../../controller/DataTableCom";
import { Edit, Delete, RemoveRedEye } from "@mui/icons-material";
import GlobalContext from "../../contexts/globalContext";
import Inputs from "../../controller/Inputs";
import StatusTableInput from "../../components/form/StatusTableInput";
import { dateFormat } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import useAsync from "../../Hooks/useAsync";
import { documentTitle } from "../../utils/documentTitle";
import Dialogs from "../../controller/Dialogs";
import MainDrawer from "../../components/modals/MainDrawer";
import Button from "../../components/form/Button";
import FormProduct from "../../components/products/InputProduit";
import ProductServices from "../../services/ProductServices";

function Produit() {
  documentTitle("Liste des produits");
  const navigate = useNavigate();
  const {
    modalIsOpen,
    closeModal,
    toggleModal,
    isUpdate,
    handleUpdate,
    handleDelete,
    companyInfo,
  } = useContext(GlobalContext);
  const { data: produits, loading } = useAsync(() =>
    ProductServices.getProduct(companyInfo.id_entrep)
  );

  const column = [
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Produit
        </Typography>
      ),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Prix original
        </Typography>
      ),
      selector: (row) => row.price + " $",
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Prix de vente
        </Typography>
      ),
      selector: (row) => row.price_red + " $",
      sortable: true,
    },

    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Satutus
        </Typography>
      ),
      sortable: true,
      cell: (row) => {
        // console.log(row.image_cat);`http://nunuaapi.afianetrdc.com/v2/upload/${row.image_cat}}`
        // let img = "http://nunuaapi.afianetrdc.com/v2/upload/" + row.image_cat;
        return (
          <Stack direction={"row"} spacing={1}>
            <StatusTableInput status={row.status} id={row.id} />
          </Stack>
        );
      },
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Image
        </Typography>
      ),
      selector: (row) => (
        <img
          src={process.env.REACT_APP_URL_IMAGE + "products/" + row.image}
          className="rounded-3"
          style={{ width: 30 }}
        />
      ),
    },
    // {
    //   name: (
    //     <Typography
    //       sx={{
    //         fontSize: 14,
    //         fontWeight: "bold",
    //       }}
    //     >
    //       Date de la creation
    //     </Typography>
    //   ),
    //   selector: (row) => dateFormat(row.created_at),
    //   sortable: true,
    // },

    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Action
        </Typography>
      ),
      button: true,
      cell: (row) => (
        <Stack direction={"row"} spacing={1}>
          <IconButton onClick={() => handleUpdate(row)}>
            <Edit color="info" />
          </IconButton>
          <IconButton onClick={() => handleDelete(row)}>
            <Delete color="error" />
          </IconButton>
          <IconButton onClick={() => navigate("/product?id=" + row.id)}>
            <RemoveRedEye color="info" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <div className="page-content">
      <div class="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
        <h4 className="text-black font-15">Liste des produits</h4>
        <Button onClick={toggleModal} title={"Ajouter un produit"} />
      </div>
      <div
        style={{
          padding: 20,
          background: "white",
          height:'100%'
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableCom loading={loading} data={produits} columns={column} />
          </Grid>
        </Grid>
      </div>
      <MainDrawer
        title={isUpdate ? "Modifier un produit" : "Créer un produit"}
        open={modalIsOpen}
        setOpen={closeModal}
        size="lg"
      >
        <FormProduct />
      </MainDrawer>
    </div>
  );
}

export default Produit;
