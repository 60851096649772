export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes) {
  const types = JSON.parse(localStorage.getItem("authUser"));
  let shopInfo;
  if (localStorage.getItem("company_informations")) {
    shopInfo = JSON.parse(localStorage.getItem("company_informations"));
    // console.log(shopInfo);
  }
  return routes.filter((route) => {
    if (shopInfo) {
      if (route?.role) {
        if (shopInfo?.permissions?.some((item) => route?.role.includes(item.pseudo))) {
          return true;
        } else {
          return false;
        }
      }
    } 
    if (!route?.type) return true;
    else if (
      types?.user_type?.some((item) => route?.type.includes(item.name))
    ) {
      return true;
    }
  });
}

export const grantTypes = (requestedRoles, permitedRoles) => {
  let result = requestedRoles?.some(item => permitedRoles?.includes(item.name));
    return result;
};

