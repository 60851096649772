import requests from "./AxiosInterceptors";

const CathegorieService = {
  getcategorie: async () => {
    return requests.get("/category");
  },
  categoryTree: async () => {
    return requests.get("/listcat");
  },
  postgategories: async (body) => {
    return requests.post("/category", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  addSubCategory: async (body) => {
    return requests.post("/createsouscat", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateCategory: async (body, id) => {
    return requests.post("/category/" + id, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteCategory: async (id) => {
    return requests.delete("/category/" + id);
  },
  updateSubCategory: async (body) => {
    return requests.post("/updatesouscatgory", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default CathegorieService;
