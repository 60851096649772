import React from "react";
import { Input, FormFeedback, Label } from "reactstrap";
const TextInputGroup = ({
  label,
  onChange,
  onBlur,
  type,
  invalid,
  symbole,
  error,
  name,
  placeholder,
  id,
  value,
}) => {
  return (
    <div className="mb-2">
      <Label className="form-label">{label}</Label>
      <div className="input-group">
        <div className="input-group-text">{symbole}</div>
        <Input
          name={name}
          invalid={invalid}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          className="form-control"
          id={id}
          placeholder={placeholder}
        />
      </div>
      {error ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
    </div>
  );
};

export default TextInputGroup;
