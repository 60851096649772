import React from "react";
import { useLocation } from "react-router-dom";
import { notifyError } from "../utils/toast";
import Swal from "sweetalert2";
import ProductServices from "../services/ProductServices";
import MarqueServices from "../services/MarqueServices";
import CathegorieService from "../services/CategorieServices";
import MesureServices from "../services/MesureServices";
import RoleServices from "../services/RoleServices";
const GlobalContext = React.createContext();

export const ContextProvider = ({ children }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [category, setCategory] = React.useState(null);
  const [brand, setBrand] = React.useState(null);
  const [unit, setUnit] = React.useState(null);
  const [description, setDescription] = React.useState("");
  const [checksuite, setSwuite] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState(null);
  const [searchedUser, setSearchedUser] = React.useState(null);
  const [permissions, setPermissions] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(
    localStorage.getItem("isAdmin") ? localStorage.getItem("isAdmin") : false
  );
  const [companyInfo, setCompanyInfo] = React.useState(
    localStorage.getItem("company_informations")
      ? JSON.parse(localStorage.getItem("company_informations"))
      : null
  );
  const [refresh, setRefresh] = React.useReducer((x) => x + 1, 0);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [modalDelete, showModalDelete] = React.useState(false);
  const [isCombination, setIsCombination] = React.useState(false);
  const [parentCategory, setParentCategory] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [icons, setIcons] = React.useState([]);
  const [logo, setLogo] = React.useState([]);
  const [banner, setBanner] = React.useState([]);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  const toggleCombination = () => setIsCombination(!isCombination);

  const handleUpdate = (data) => {
    setIsUpdate(true);
    setSelectedId(data);
    setModalIsOpen(true);
    if (location.pathname === "categories") {
      setParentCategory(data.parent_id);
    }
  };
  const handleDelete = (data) => {
    setSelectedId(data);
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraient supprimer cet élément ?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
    }).then((result) => {
      if (result.isConfirmed) {
        if (location.pathname === "/products") {
          ProductServices.deleteProduct(companyInfo.id_entrep, data.id)
            .then((res) => {
              setRefresh();
              Swal.fire("Deleted!", res.data.message, "success");
            })
            .catch((err) => {
              notifyError(err ? err.response.data.message : err.message);
            });
        }
        if (location.pathname === "/brands") {
          MarqueServices.deleteMarque(data.id)
            .then((res) => {
              setRefresh();
              Swal.fire("Deleted!", res.data.message, "success");
            })
            .catch((err) => {
              notifyError(err ? err.response.data.message : err.message);
            });
        }
        if (location.pathname === "/units") {
          MesureServices.deleteMesure(companyInfo.id_entrep, data.id)
            .then((res) => {
              setRefresh();
              Swal.fire("Deleted!", res.data.message, "success");
            })
            .catch((err) => {
              notifyError(err ? err.response.data.message : err.message);
            });
        }
        if (location.pathname === "/roles") {
          RoleServices.deleteRole(companyInfo.id_entrep, data.id)
            .then((res) => {
              setRefresh();
              Swal.fire("Deleted!", res.data.message, "success");
            })
            .catch((err) => {
              notifyError(err ? err.response.data.message : err.message);
            });
        }
        if (location.pathname === "/categories") {
          CathegorieService.deleteCategory(data.id)
            .then((res) => {
              setRefresh();
              Swal.fire("Deleted!", res.data.message, "success");
            })
            .catch((err) => {
              notifyError(err ? err.response.data.message : err.message);
            });
        }
        //Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  const closeModal = () => {
    setImages([]);
    setIsUpdate(false);
    setParentCategory(null);
    setCategory(null);
    setBrand(null);
    setUnit(null);
    setSelectedId(null);
    setModalIsOpen(false);
    setPermissions([]);
  };
  React.useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <GlobalContext.Provider
      value={{
        isLoading,
        handleUpdate,
        closeModal,
        setIsLoading,
        toggleModal,
        setModalIsOpen,
        modalIsOpen,
        setIsUpdate,
        isUpdate,
        searchedUser,
        setSearchedUser,
        images,
        refresh,
        toggleCombination,
        isCombination,
        logo,
        setLogo,
        banner,
        category,
        isOnline,
        setCategory,
        parentCategory,
        setParentCategory,
        selectedRole,
        setSelectedRole,
        companyInfo,
        setCompanyInfo,
        userInfo,
        setUserInfo,
        brand,
        setBrand,
        isAdmin,
        setIsAdmin,
        permissions,
        setPermissions,
        selectedId,
        setSelectedId,
        checksuite,
        handleDelete,
        setSwuite,
        unit,
        setUnit,
        icons,
        setIcons,
        description,
        setDescription,
        setBanner,
        setRefresh,
        setImages,
        selectedId,
        setSelectedId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
