import { Button } from "@mui/material";
import React from "react";

function Boutons(props) {
  return (
    <Button
      disabled={props.disabled}
      variant={props.variant}
      sx={{
        // mt: 2,
        background: "#44CAF5",
        color: "#FFF",
        width: "100%",
        "&:hover": {
          background: "#3AAFD0",
        },
      }}
      onClick={props.onClick}
      type={props.type}
      onLoad={true}
    >
      {props.icons}
      {props.title}
    </Button>
  );
}

export default Boutons;
