import React, { useContext, useState, useEffect } from "react";
import UploaderMu from "../../controller/UploadImageAnted";
import Button from "../../components/form/Button";
import {
  Card,
  CardBody,
  CardText,
  Label,
  CardTitle,
  Col,
  Collapse,
  Container,
  FormFeedback,
  CardImg,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { documentTitle } from "../../utils/documentTitle";
import ButtonRadio from "../../components/form/ButtonRadio";
import SettingServices from "../../services/SettingServices";
import GlobalContext from "../../contexts/globalContext";
import { useSelector, useDispatch } from "react-redux";
import useAsync from "../../Hooks/useAsync";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAdminSubmit from "../../Hooks/useAdminSubmit";
import TextInput from "../../components/form/TextInput";
import classnames from "classnames";
function UserProfile() {
  const { logo, setLogo, banner, setBanner, userInfo, setUserInfo } =
    useContext(GlobalContext);
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const { data, loading } = useAsync(SettingServices.getCountry);
  documentTitle("Profile");
  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  const { editProfile } = useAdminSubmit();
  const dispatch = useDispatch();
  const { error, success } = useSelector((state) => ({
    error: state.profile.error,
    success: state.profile.success,
  }));
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserInfo(obj);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: userInfo ? userInfo?.full_name : "",
      email: userInfo ? userInfo?.email : "",
      phone: userInfo ? userInfo?.phone : "",
      sexe: userInfo ? userInfo?.gender : "",
      ville:userInfo ? userInfo?.ville.id : "",
      pays:userInfo ? userInfo?.pays.id : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Ce champs est requis"),
      email: Yup.string()
        .email("Veuillez entrer une adresse email valide")
        .required("Ce champs est requis"),
      phone: Yup.string().required("Ce champs est requis"),
      sexe: Yup.string()
        .required()
        .oneOf(["Homme", "Femme", "Autre"])
        .label("Selected Country"),
    }),
    onSubmit: (values) => {
      editProfile(values);
    },
  });

  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      old: "",
      new: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      old: Yup.string().required("Ce champs est requis"),
      new: Yup.string().required("Ce champs est requis"),
      confirm: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className="page-content">
      <h4
        style={{
          background: "white",
          padding: 20,
          borderRadius: 5,
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        Mon compte
      </h4>

      <Col xl={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md="2">
                <Nav pills className="flex-column">
                  <div
                    style={{
                      marginBottom: 20,
                    }}
                    className="justify-content-center align-items-center w-100 text-center"
                  >
                    <CardImg
                      src={
                        userInfo?.provider
                          ? userInfo?.profil
                          : process.env.REACT_APP_URL_IMAGE +
                            "user/" +
                            userInfo?.profil
                      }
                      alt=""
                      className="rounded-circle avatar-lg mb-2"
                    />
                    <p>{userInfo?.full_name}</p>
                    <small className="mb-2">{userInfo?.email}</small>
                  </div>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-home-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleVertical("1");
                      }}
                    >
                      <i className="dripicons-user me-2 align-middle"> </i>{" "}
                      Informations
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-profile-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleVertical("2");
                      }}
                    >
                      <i className="dripicons-pencil me-2 align-middle"></i>{" "}
                      Modifier
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-messages-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleVertical("3");
                      }}
                    >
                      <i className="dripicons-lock me-2 align-middle"></i> Mot
                      de passe
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="10">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <div
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            value={userInfo?.full_name}
                            disabled={true}
                            label={"Nom complet"}
                            placeholder={"Nom complet"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            value={userInfo?.email}
                            disabled={true}
                            label={"Email"}
                            placeholder={"Email"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            value={userInfo?.phone}
                            disabled={true}
                            label={"Phone"}
                            placeholder={"Phone"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">Sexe</Label>
                          <select
                            disabled
                            value={userInfo?.gender}
                            className="form-select"
                            id="inlineFormSelectPref"
                          >
                            <option defaultValue>Choose...</option>
                            <option value="Homme">Homme</option>
                            <option value="Femme">Femme</option>
                            <option value="Autre">Autre</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">Pays</Label>
                          <select
                            disabled
                            style={{
                              width: "100%",
                            }}
                            value={userInfo?.pays?.id}
                            className="form-select"
                            id="inlineFormSelectPref"
                          >
                            <option defaultValue>Choose...</option>
                            {data?.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-6 form-group">
                          <Label className="form-label">Ville</Label>
                          <select
                            style={{
                              width: "100%",
                            }}
                            disabled
                            value={userInfo?.ville?.id}
                            className="form-select"
                            id="inlineFormSelectPref"
                          >
                            <option defaultValue>Choose...</option>
                            {data
                              ?.find((item) => item.code === "CD")
                              ?.ville?.map((item) => (
                                <option value={item.id}>{item.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom complet"}
                            placeholder={"Nom complet"}
                            name="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                            error={validation.errors.name}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Email"}
                            placeholder={"Email"}
                            name="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                            error={validation.errors.email}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Phone"}
                            placeholder={"Phone"}
                            name="phone"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                            error={validation.errors.phone}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">Sexe</Label>
                          <select
                            className="form-select"
                            id="inlineFormSelectPref"
                            name="sexe"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sexe}
                            invalid={
                              validation.touched.sexe && validation.errors.sexe
                                ? true
                                : false
                            }
                          >
                            <option value="" defaultValue>
                              Choose...
                            </option>
                            <option value="Homme">Homme</option>
                            <option value="Femme">Femme</option>
                            <option value="Autre">Autre</option>
                          </select>
                          {validation.touched.sexe &&
                            validation.errors.sexe && (
                              <FormFeedback type="invalid">
                                {validation.errors.sexe}
                              </FormFeedback>
                            )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">Pays</Label>
                          <select
                            disabled
                            style={{
                              width: "100%",
                            }}
                            className="form-select"
                            id="inlineFormSelectPref"
                            name="pays"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pays}
                            invalid={
                              validation.touched.pays && validation.errors.pays
                                ? true
                                : false
                            }
                          >
                            <option defaultValue>Choose...</option>
                            {data?.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-6 form-group">
                          <Label className="form-label">Ville</Label>
                          <select
                            
                            style={{
                              width: "100%",
                            }}
                            className="form-select"
                            id="inlineFormSelectPref"
                            name="ville"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ville}
                            invalid={
                              validation.touched.ville && validation.errors.ville
                                ? true
                                : false
                            }
                          >
                            <option defaultValue>Choose...</option>
                            {data
                              ?.find((item) => item.code === "CD")
                              ?.ville?.map((item) => (
                                <option value={item.id}>{item.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3 justify-content-end">
                          <Button type="submit" title={"Enregistrer"} />
                        </div>
                      </div>
                    </form>
                  </TabPane>
                  <TabPane tabId="3">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation1.handleSubmit();
                        return false;
                      }}
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-4 form-group">
                          <TextInput
                            label={"Ancient mot de passe"}
                            type="password"
                            placeholder={"Ancient mot de passe"}
                            name="old"
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.old}
                            invalid={
                              validation1.touched.old && validation1.errors.old
                                ? true
                                : false
                            }
                            error={validation1.errors.old}
                          />
                        </div>
                        <div className="col-lg-4 form-group">
                          <TextInput
                            type="password"
                            label={"Nouveau mot de passe"}
                            placeholder={"Nouveau mot de passe"}
                            name="new"
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.new}
                            invalid={
                              validation1.touched.new && validation1.errors.new
                                ? true
                                : false
                            }
                            error={validation1.errors.new}
                          />
                        </div>
                        <div className="col-lg-4 form-group">
                          <TextInput
                            type="password"
                            label={"Confirmation mot de passe"}
                            placeholder={"Confirmation mot de passe"}
                            name="confirm"
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.confirm}
                            invalid={
                              validation1.touched.confirm &&
                              validation1.errors.confirm
                                ? true
                                : false
                            }
                            error={validation1.errors.confirm}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3 justify-content-end">
                          <Button type="submit" title={"Enregistrer"} />
                        </div>
                      </div>
                    </form>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default UserProfile;
