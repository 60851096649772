import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import React from "react";

function PhoneNumber(props) {
  return (
    <PhoneInput country={"cd"} value={props.value} onChange={props.onChange} />
  );
}

export default PhoneNumber;
