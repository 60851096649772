import React from 'react';

import img1 from '../assets/images/logo-dark.png'
import img2 from '../assets/images/logo-light.png'
import {RiWifiOffLine} from'react-icons/ri'
import { Container, Row, Col, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { documentTitle } from '../utils/documentTitle';




const NoConnection = () => {
    documentTitle("Pas de connexion")

    return (
        <React.Fragment>
            <div className="py-5">
                <Container>


                    <Row className="justify-content-center">
                        <Col md={4}>
                            <div className="text-center">
                                <CardBody>
                                    <div className="avatar-xl mx-auto mb-4">
                                        <div className="avatar-title rounded-circle">
                                           <RiWifiOffLine size={50}/>
                                        </div>
                                    </div>
                                    <h5 className="font-size-15">Pas de connexion </h5>
                                    <p className="mb-0">Votre appareil n'est pas connecté à l'internet, vérifiez votre connexion internet</p>
                                </CardBody>
                            </div>
                        </Col>

                    </Row>

                </Container>

            </div>
        </React.Fragment>
    )
}

export default NoConnection;
