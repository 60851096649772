import React from "react";
import DataTableCom from "../../controller/DataTableCom";
import Inputs from "../../controller/Inputs";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import useAsync from "../../Hooks/useAsync";
import MarqueServices from "../../services/MarqueServices";
import { documentTitle } from "../../utils/documentTitle";
import GlobalContext from "../../contexts/globalContext";
import ModalDelete from "../../components/modals/SwalDelete";
import StatusTableInput from "../../components/form/StatusTableInput";
import { Delete, Edit } from "@mui/icons-material";
import MarqueForm from "../../components/marques/MarqueForm";
import MainModal from "../../components/modals/MainModal";
import { dateFormat } from "../../utils/date";
import Button from "../../components/form/Button";

function Marque() {
  documentTitle("Marques");

  const { data: marques, loading } = useAsync(MarqueServices.allMarque);
  const { modalIsOpen, setModalIsOpen, isUpdate, handleUpdate, handleDelete } =
    React.useContext(GlobalContext);
    const column = [
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Image
        </Typography>
      ),
      selector: (row) => (
        <img
          src={process.env.REACT_APP_URL_IMAGE +'marques/' + row.image}
          className="rounded-3"
          style={{ width: 30 }}
        />
      ),
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Nom de la Marque
        </Typography>
      ),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Satutus
        </Typography>
      ), sortable: true,
      cell: (row) => {
        // console.log(row.image_cat);`http://nunuaapi.afianetrdc.com/v2/upload/${row.image_cat}}`
        // let img = "http://nunuaapi.afianetrdc.com/v2/upload/" + row.image_cat;
        return (
          <Stack direction={"row"} spacing={1}>
                 <StatusTableInput status={row.status} id={row.id} />
          </Stack>
        );
      },
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Date de la creation
        </Typography>
      ),
      selector: (row) => dateFormat(row.created_at),
      sortable: true,
    },

    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Actions
        </Typography>
      ),
      button: true,
      cell: (row) => (
        <Stack direction={"row"} spacing={1}>
          <IconButton onClick={() => handleUpdate(row)}>
            <Edit color="info" />
          </IconButton>
          <IconButton onClick={() => handleDelete(row)} >
            <Delete color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];
  return (
    <div className="page-content">
      <div class="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
        <h4 className="text-black font-15">Liste des marques</h4>
        <Button
          title={"Ajouter une marque"}
          onClick={() => setModalIsOpen(true)}
        />
      </div>
      <div
        style={{
          padding: 20,
          background: "white",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
       

            <DataTableCom loading={loading} data={marques} columns={column} />
          </Grid>
        </Grid>
        <MainModal
          size="md"
          title={isUpdate ? "Modifier une marque" : "Ajouter une marque"}
          open={modalIsOpen}
          setOpen={setModalIsOpen}
        >
          <MarqueForm isUpdate={isUpdate} />
        </MainModal>
      </div>
    </div>
  );
}

export default Marque;
