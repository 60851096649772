import React from "react";
import { Form, Modal } from "reactstrap";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import GlobalContext from "../../contexts/globalContext";
const MainDrawer = ({ size, open, title, children }) => {
  const { closeModal } = React.useContext(GlobalContext);
  return (
    <div className="row">
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <Offcanvas
        fade={true}
        direction='end'
        className="w-lg-100"
          isOpen={open}
          on
          backdrop={true}
          scrollable
          toggle={closeModal}
        >
          <OffcanvasHeader toggle={closeModal}>{title}</OffcanvasHeader>
          <OffcanvasBody>{children}</OffcanvasBody>
        </Offcanvas>
      </div>
    </div>
  );
};

export default MainDrawer;
