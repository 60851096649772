import React from "react";

const Button = ({ isLoading, title, type, onClick }) => {
  return (
    <div className="d-grid">
      <button
        disabled={isLoading}
        onClick={onClick}
        className="btn btn-primary waves-effect waves-light btn-rounded"
        type={type}
      >
        <span
          className="mr-2"
          style={{
            marginRight: 2,
          }}
        >
          {title}
        </span>
        {isLoading && (
          <div
            class="spinner-border text-light spinner-border-sm ml-4"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </button>
    </div>
  );
};

export default Button;
