import React from "react";
import { Form, Modal } from "reactstrap";
import GlobalContext from "../../contexts/globalContext";
const MainModal = ({
  size,
  isLoading,
  open,
  setOpen,
  title,
  children,
  onSubmit,
}) => {
  const { closeModal } = React.useContext(GlobalContext);
  return (
    <div>
      <Modal size={size} centered isOpen={open} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            onClick={closeModal}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-4">{children}</div>
      </Modal>
    </div>
  );
};

export default MainModal;
