import React from "react";
import Inputs from "../../controller/Inputs";
import { Email, EmojiObjects, Phone } from "@mui/icons-material";
import Boutons from "../../controller/Buttonsx";
import { Grid, Stack, Typography } from "@mui/material";
import PhoneNumber from "../../controller/PhoneNumber";

function InputEntreprise() {
  return (
    <Grid
      container
      // sx={{
      //   width: "100%",
      // }}
    >
      <Grid item md={12}>
        <div
          style={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              ml: 5,
              mb: -2,
              fontWeight: "bold",
            }}
          >
            Entreprise
          </Typography>
          <Inputs
            // multiline={true}
            icons={
              <EmojiObjects
                fontSize="large"
                sx={{ mr: 1, my: 0.5, color: "#192C56" }}
              />
            }
          />
        </div>
        <Stack
          direction={"row"}
          spacing={5}
          sx={{
            mt: 2,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                ml: 5,
                mb: 0.5,
                fontWeight: "bold",
              }}
            >
              Phone
            </Typography>
            {/*
             <Inputs
              // multiline={true}
              icons={
                <Phone
                  fontSize="large"
                  sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                />
              }
                />
            */}
            <PhoneNumber />
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                ml: 5,
                mb: -2,
                fontWeight: "bold",
              }}
            >
              Email
            </Typography>
            <Inputs
              // multiline={true}
              icons={
                <Email
                  fontSize="large"
                  sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                />
              }
            />
          </div>
        </Stack>
        <Stack
          direction={"row"}
          spacing={5}
          sx={{
            mt: 2,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                ml: 5,
                mb: -2,
                fontWeight: "bold",
              }}
            >
              ID Nat
            </Typography>
            <Inputs
              // multiline={true}
              icons={
                <EmojiObjects
                  fontSize="large"
                  sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                />
              }
            />
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                ml: 5,
                mb: -2,
                fontWeight: "bold",
              }}
            >
              RCCM
            </Typography>
            <Inputs
              // multiline={true}
              icons={
                <EmojiObjects
                  fontSize="large"
                  sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                />
              }
            />
          </div>
        </Stack>
        <div
          style={{
            width: "100%",
            marginTop: 20,
          }}
        >
          <Typography
            sx={{
              ml: 5,
              mb: -2,
              fontWeight: "bold",
            }}
          >
            Description
          </Typography>
          <Inputs
            multiline={true}
            icons={
              <EmojiObjects
                fontSize="large"
                sx={{ mr: 1, my: 0.5, color: "#192C56" }}
              />
            }
          />
        </div>
        <Stack
          sx={{
            // width: "100%",
            mt: 2,
            ml: 5,
          }}
        >
          <Boutons title="Enregistrer" />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default InputEntreprise;
