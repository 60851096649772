import MarqueServices from "../services/MarqueServices";
import { notifyError, notifySuccess } from "../utils/toast";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductServices from "../services/ProductServices";
import GlobalContext from "../contexts/globalContext";

const useProductSubmit = (selectedId) => {
  const {
    isLoading,
    setIsLoading,
    images,
    setImages,
    category,
    brand,
    unit,
    setUnit,
    setBrand,
    parentCategory,
    setParentCategory,
    setCategory,
    setSwuite,
    companyInfo,
    closeModal,
    setRefresh,
  } = React.useContext(GlobalContext);

  const submit = async (values) => {
    const data = new FormData();
    data.append("name", values.name);
    data.append("description", values.description);
    data.append("price", values.originalPrice);
    data.append("price_red", values.price);
    data.append("qte", values.quantity);
    data.append("id_category", category);
    data.append("id_mesure", unit?.value );
    if (brand) {
      data.append("id_marque", brand?.value);
    }
    for (let i = 0; i < images.length; i++) {
      if (images[i].originFileObj) {
        data.append("images[]", images[i].originFileObj);
      }
    }
    if (selectedId) {
      setIsLoading(true);
      ProductServices.updateProduct(data, companyInfo.id_entrep, selectedId.id)
        .then((res) => {
          setRefresh();
          closeModal();
          notifySuccess(res.data.message);
          setCategory(null);
          setUnit(null);
          setBrand(null);
          setIsLoading(false);
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
          setIsLoading(false);
        });
    } else {
      if (images.length > 0) {
        setIsLoading(true);
        ProductServices.createProduct(data, companyInfo.id_entrep)
          .then((res) => {
            setCategory(null);
            setUnit(null);
            setBrand(null)
            setIsLoading(false);
            setRefresh();
            closeModal();
            notifySuccess(res.data.message);
          })
          .catch((err) => {
            notifyError(err ? err.response.data.message : err.message);
            setIsLoading(false);
          });
      } else {
        //save in categorie
        notifyError("l'image est obligatoire");
        setIsLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (selectedId) {
      setCategory(selectedId?.id_category);
      setBrand({value:selectedId?.id_marque, label:selectedId?.marque});
      setUnit({value:selectedId?.id_mesure, label:selectedId?.mesure});

      setImages(
        selectedId?.images?.map((item) => {
          return {
            uid: item.id,
            name: item.id,
            status: "done",
            url: process.env.REACT_APP_URL_IMAGE + "products/" + item.images,
          };
        })
      )
    }
  }, [selectedId]);

  return {
    submit,
    isLoading,
    setIsLoading,
  };
};

export default useProductSubmit;
