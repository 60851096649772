import requests from "./AxiosInterceptors";

const SettingServices = {
  allCompanies: async () => {
    return requests.get("/user/entreprises");
  },
  getPermissions: async (body) => {
    return requests.post("/permission_role_user", body);
  },
  getCountry: async () => {
    return requests.get("/listCountry");
  },
  getAllSettings: async () => {
    return requests.get("/global/settings");
  },
  saveSettings: async (body) => {
    return requests.post("/global/settings", body);
  },
  saveAbout: async (body) => {
    return requests.post("/global/about_us", body);
  },
  saveSocial: async (body) => {
    return requests.post("/global/social", body);
  },
  createMarque: async (body) => {
    return requests.post("/createmarque", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateMarque: async (body) => {
    return requests.post("/updatemarque", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default SettingServices;
