import React from "react";
import { Input, FormFeedback, Label } from "reactstrap";
const TextArea = ({
  label,
  onChange,
  onBlur,
  type,
  invalid,
  error,
  name,
  placeholder,
  id,
  value,
}) => {
  return (
    <div className="mb-2">
      <Label className="form-label">{label}</Label>
      <Input
      className="form-control"
        name={name}
        placeholder={placeholder}
        id={id}
        rows={10}
        type={'textarea'}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
      />
      {error ? <small className="text-danger">{error}</small> : null}
    </div>
  );
};

export default TextArea;
