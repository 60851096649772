import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Inputs from "../../controller/Inputs";
import { Delete, Search, Update } from "@mui/icons-material";
import DataTableCom from "../../controller/DataTableCom";
import Boutons from "../../controller/Buttonsx";
import InputEntreprise from "./InputEntreprise";
import StatusTableInput from "../../components/form/StatusTableInput";
import { dateFormat } from "../../utils/date";
import EngagementServices from "../../services/EngagementServices";
import Dialogs from "../../controller/Dialogs";
import { documentTitle } from "../../utils/documentTitle";
import useAsync from "../../Hooks/useAsync";

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Entreprise
      </Typography>
    ),
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Phone
      </Typography>
    ),
    selector: (row) => row.phone,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        RCCM/PATENTE
      </Typography>
    ),
    selector: (row) => row.rccm,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        ID Nat
      </Typography>
    ),
    selector: (row) => row.idnat,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Satutus
      </Typography>
    ),
    cell: (row) => {
      return (
        <Stack direction={"row"} spacing={1}>
          <StatusTableInput status={row.status} id={row.id} />
        </Stack>
      );
    },
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Image
      </Typography>
    ),
    selector: (row) => (
      <img
        src={process.env.REACT_APP_URL_IMAGE + "entreprises/" + row.logo}
        className="rounded-3"
        style={{ width: 30 }}
      />
    ),
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Création
      </Typography>
    ),
    selector: (row) => dateFormat(row.created_at),
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => "",
    sortable: true,
  },
];

function Entreprise() {
  documentTitle("Entreprises");
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const { data, loading } = useAsync(EngagementServices.getAllEntreprise);

  return (
    <div className="page-content">
      <h4
        style={{
          background: "white",
          padding: 20,
          borderRadius: 5,
        }}
      >
        Entreprises
      </h4>
      <div
        style={{
          padding: 20,
          background: "white",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableCom loading={loading} data={data} columns={column} />
          </Grid>
        </Grid>
      </div>
      <Dialogs
        title={"Nouvelle entreprise"}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="lg"
      >
        <InputEntreprise />
      </Dialogs>
    </div>
  );
}

export default Entreprise;
