import React, { useState } from "react";
import EmployeeForm1 from "./EmployeeForm1";
import EmployeeForm2 from "./EmployeeForm2";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
const EmployeeForm = ({ isUpdate, permissions, roles }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  return (
    <div>
      <Col xl={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md="3">
                <Nav pills className="flex-column">
                  <NavItem>
                    <NavLink
                      id="v-pills-left-home-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleVertical("1");
                      }}
                    >
                   <i className="dripicons-web me-2 align-middle"></i>{" "}
                      Utilisateur existant
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      id="v-pills-left-profile-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleVertical("2");
                      }}
                    >
                      <i className="dripicons-web me-2 align-middle"></i>{" "}
                      Utilisateur existant
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <EmployeeForm1 permissions={permissions} roles={roles}/>
                  </TabPane>
                  <TabPane tabId="2">
                    <EmployeeForm2/>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default EmployeeForm;
