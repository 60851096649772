import React from "react";
import { Images } from "../../constants/images";
const Header = ({title, description}) => {
  return (
    <div>
      <div className="text-center">
        
          <img
            src={Images.appLogo}
            alt=""
            height="55"
            className="auth-logo logo-dark mx-auto"
          />
          {/* <img
            src={Images.appLogo}
            alt=""
            height="55"
            className="auth-logo logo-light mx-auto"
          /> */}
      
      </div>
      <h4 className="font-size-18 text-muted mt-2 text-center">{title}</h4>
      <p className="mb-5 text-center">{description}</p>
    </div>
  );
};

export default Header;
