import requests from "./AxiosInterceptors";

const RoleServices = {
  getRoles: async (id) => {
    return requests.get("/role/" + id);
  },
  createRole: async (body) => {
    return requests.post("/role", body);
  },
  updateRole: async (body, id) => {
    return requests.put("/role/" + id, body);
  },

  deleteRole: async (entreprise,id) => {
    return requests.delete(`/role/${entreprise}/${id}`);
  },
  getPermissions: async () => {
    return requests.get("/permissions");
  },

};

export default RoleServices;
