import React from "react";

function Colors() {
  return {
    colorsPrincipel: "#44CAF5",
    colorsSecondaire: "#3AAFD0",
  };
}

export default Colors;
