import { notifyError, notifySuccess } from "../utils/toast";
import React from "react";
import GlobalContext from "../contexts/globalContext";
import RoleServices from "../services/RoleServices";
const useRoleSubmit = (selectedId) => {
  const { isLoading, setIsLoading, companyInfo, closeModal, setRefresh } =
    React.useContext(GlobalContext);

  const submit = async (values) => {
    setIsLoading(true);
    let data = {
      name: values.name,
      id_entrep: companyInfo.id_entrep,
    };
    if (selectedId) {
      RoleServices.updateRole(data, selectedId.id)
        .then((res) => {
          setRefresh();
          closeModal();
          notifySuccess(res.data.message);
          setIsLoading(false);
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
          setIsLoading(false);
        });
    } else {
      RoleServices.createRole(data)
        .then((res) => {
          setIsLoading(false);
          setRefresh();
          closeModal();
          notifySuccess(res.data.message);
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
          setIsLoading(false);
        });
    }
  };

  return {
    submit,
    isLoading,
    setIsLoading,
  };
};

export default useRoleSubmit;
