import React, { useContext, useState } from "react";
import UploaderMu from "../../controller/UploadImageAnted";
import Button from "../../components/form/Button";
import {
  Card,
  CardBody,
  CardText,
  Label,
  CardTitle,
  Col,
  Collapse,
  Container,
  CardImg,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { documentTitle } from "../../utils/documentTitle";
import GlobalContext from "../../contexts/globalContext";
import TextInput from "../../components/form/TextInput";
import classnames from "classnames";
function CompanySettings() {
  const { logo, setLogo, banner, setBanner } = useContext(GlobalContext);
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  documentTitle("Réglages de l'entreprise");
  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  return (
    <div className="page-content">
      <h4
        style={{
          background: "white",
          padding: 20,
          borderRadius: 5,
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        Réglages de l'entreprise
      </h4>

      <Col xl={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md="2">
                <Nav pills className="flex-column">
                  <div className="justify-content-center align-items-center w-100 text-center">
                    <CardImg
                      src="https://img.freepik.com/vecteurs-libre/vecteur-degrade-logo-colore-oiseau_343694-1365.jpg"
                      alt=""
                      className="rounded avatar-xl"
                    />
                  </div>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-home-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleVertical("1");
                      }}
                    >
                      <i className="dripicons-home me-2 align-middle"> </i>{" "}
                      Informations
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-profile-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleVertical("2");
                      }}
                    >
                      <i className="dripicons-pencil me-2 align-middle"></i>{" "}
                      Modifier
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="v-pills-left-messages-tab"
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleVertical("3");
                      }}
                    >
                      <i className="dripicons-photo me-2 align-middle"></i>{" "}
                      Logo et banderole
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="10">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <div
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">
                            Logo de l'entreprise
                          </Label>
                          <UploaderMu
                            image={logo}
                            setImages={setLogo}
                            limite={1}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">
                            Banderole de l'entreprise
                          </Label>
                          <UploaderMu
                            image={banner}
                            setImages={setBanner}
                            limite={1}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3 justify-content-end">
                          <Button title={"Enregistrer"} />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <TextInput
                            label={"Nom de l'entreprise"}
                            placeholder={"Nom de l'entreprise"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 justify-content-start">
                          <Button title={"Enregistrer"} />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div
                      style={{
                        padding: 20,
                        background: "white",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">
                            Logo de l'entreprise
                          </Label>
                          <UploaderMu
                            image={logo}
                            setImages={setLogo}
                            limite={1}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <Label className="form-label">
                            Banderole de l'entreprise
                          </Label>
                          <UploaderMu
                            image={banner}
                            setImages={setBanner}
                            limite={1}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 justify-content-start">
                          <Button title={"Enregistrer"} />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default CompanySettings;
