import React from "react";
import PropTypes from "prop-types";
import withRouter from "../components/Common/withRouter";
import { Navigate } from "react-router-dom";

const NonAuthLayout = (props) => {
  // const nav = useNavigate();
  const auth = localStorage.getItem("authUser");

  return auth ? (
    <Navigate to="/" />
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
