import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Boutons from "../../controller/Buttonsx";
import Inputs from "../../controller/Inputs";
import Comboboxs from "../../controller/ComboBox";
import SelectTwo from "../../components/form/SelectTwo";
import useAsync from "../../Hooks/useAsync";
import CathegorieService from "../../services/CategorieServices";
import useCategorie from "../../Hooks/useCategorie";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/form/TextInput";
import GlobalContext from "../../contexts/globalContext";
import { Form, Label } from "reactstrap";
import Button from "../../components/form/Button";
import UploaderMu from "../../controller/UploadImageAnted";
import { notifyError, notifySuccess } from "../../utils/toast";
import "react-dropdown-tree-select/dist/styles.css";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { TreeSelect } from "antd";
import useCategorySubmit from "../../Hooks/useCategorySubmit";
import requests from "../../services/AxiosInterceptors";

function FormCathegories({ dataupdate, etat }) {
  const { data } = useAsync(CathegorieService.categoryTree);
  const [data1, setData1] = React.useState([]);
  const [valuecath, setValueCat] = React.useState("");
  const {
    category,
    setCategory,
    parentCategory,
    setParentCategory,
    isUpdate,
    selectedId,
    images,
    icons,
    setIcons,
    setImages,
  } = React.useContext(GlobalContext);
  const { isLoading, setIsLoading, submit } = useCategorySubmit(selectedId);

  // console.log(dataupdate);
  const renderCategories = (categories) => {
    let myCategories = [];
    for (let category of categories) {
      myCategories.push({
        title: category.name,
        value: category.id,
        children:
          category.children.length > 0 && renderCategories(category.children),
      });
    }

    return myCategories;
  };

  const onChange = (val) => {
    setParentCategory(val);
  };

  const mapDta = () => {
    let imx = data.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
    setData1(imx);
  };

  React.useEffect(() => {
    mapDta();
  }, [data]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name_Cat: selectedId?.name
        ? selectedId?.name
        : selectedId?.name
        ? selectedId?.name
        : "",
    },

    validationSchema: Yup.object({
      name_Cat: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      submit(values);
      // setIsLoading(true);
      // if (etat === "save") {
      //   if (valuecath === "") {
      //     //insertion categoreis
      //     if (image.length > 0) {
      //       const data = new FormData();
      //       data.append("name_cat", values.name_Cat);
      //       data.append("image_cat", image[0].originFileObj);
      //       requests
      //         .post("/createcat", data, {
      //           headers: {
      //             "Content-Type": "multipart/form-data",
      //           },
      //         })
      //         .then((e) => {
      //           if (e.status === 200) {
      //             notifySuccess(e.data.message);
      //             setIsLoading(false);
      //           } else {
      //             notifyError("Error d'enregistrement");
      //             setIsLoading(false);
      //           }
      //         })
      //         .catch((error) => {
      //           notifySuccess(error.response.data.message);
      //           setIsLoading(false);
      //         });
      //     } else {
      //       //save in categorie
      //       notifyError("image est obligatoire");
      //       setIsLoading(false);
      //     }
      //   } else {
      //     //insertion sous categorie
      //     if (image.length > 0) {
      //       const data = new FormData();
      //       // console.log("====> niko value:===>", values);
      //       data.append("name_sous", values.name_Cat);
      //       data.append("id_cat", values.nam_cat_chiel);
      //       data.append("image_sous", image[0].originFileObj);
      //       requests
      //         .post("/createsouscat", data, {
      //           headers: {
      //             "Content-Type": "multipart/form-data",
      //           },
      //         })
      //         .then((e) => {
      //           // console.log(e.data);
      //           if (e.status === 200) {
      //             notifySuccess(e.data.message);
      //             setIsLoading(false);
      //           } else {
      //             notifyError("Error d'enregistrement");
      //             setIsLoading(false);
      //           }
      //         })
      //         .catch((error) => {
      //           console.log(error);
      //           notifySuccess(error.response.data.message);
      //           setIsLoading(false);
      //         });
      //     } else {
      //       //error image est obligatoire
      //       notifyError("image est obligatoire");
      //       setIsLoading(false);
      //     }
      //   }
      // } else {
      //   //modification
      //   if (valuecath === "") {
      //     //modification categorie
      //     const data = new FormData();
      //     data.append("name_cate", values.name_Cat);
      //     data.append(
      //       "image_cat",
      //       image.length > 0 ? image[0].originFileObj : null
      //     );
      //     data.append("id_cat", dataupdate.id_cat);
      //     // console.log("get idcat", dataupdate.id_cat);
      //     requests
      //       .post("/updatecatgory", data, {
      //         headers: {
      //           "Content-Type": "multipart/form-data",
      //         },
      //       })
      //       .then((e) => {
      //         if (e.status === 200) {
      //           console.log("get data", e.data);
      //           notifySuccess(e.data.message);
      //           setIsLoading(false);
      //         } else {
      //           notifyError("Error d'enregistrement");
      //           setIsLoading(false);
      //         }
      //       })
      //       .catch((error) => {
      //         notifySuccess(error.response.data.message);
      //         setIsLoading(false);
      //       });
      //   } else {
      //     //modofication in sous categorie
      //     const data = new FormData();
      //     // console.log("====> niko value:===>", valuecath.value);
      //     data.append("name_sous", values.name_Cat);
      //     data.append(
      //       "id_cat",
      //       valuecath.value === undefined ? "" : valuecath.value
      //     );
      //     data.append("id_sous", dataupdate.id_sous);
      //     data.append(
      //       "image_souscat",
      //       image.length > 0 ? image[0].originFileObj : null
      //     );
      //     requests
      //       .post("/updatesouscatgory", data, {
      //         headers: {
      //           "Content-Type": "multipart/form-data",
      //         },
      //       })
      //       .then((e) => {
      //         console.log(e.data);
      //         if (e.status === 200) {
      //           notifySuccess(e.data.message);
      //           setIsLoading(false);
      //         } else {
      //           notifyError("Error d'enregistrement");
      //           setIsLoading(false);
      //         }
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         notifySuccess(error.response.data.message);
      //         setIsLoading(false);
      //       });
      //   }
      // }
    },
  });

  return (
    <div>
      <Grid container>
        <Grid item lg={12} sm={12} md={12}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="row">
              <div className="form-group">
                <TextInput
                  id="name_Cat"
                  value={validation.values.name_Cat}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.name_Cat && validation.errors.name_Cat
                      ? true
                      : false
                  }
                  error={validation.values.name_Cat}
                  // invalid={}
                  label="Catgéorie"
                />
              </div>

              <div className="form-group w-100" style={{ marginBottom: 10 }}>
                <Label className="form-label">Catégorie mère</Label>
                <TreeSelect
                  showSearch
                  value={parentCategory}
                  onChange={onChange}
                  style={{
                    width: "100%",
                  }}
                  bordered={false}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  className="form-control"
                  placeholder="Select"
                  allowClear
                  treeData={renderCategories(data)}
                />
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <Label className="form-label">Image</Label>
                    <UploaderMu
                      image={images}
                      setImages={setImages}
                      limite={1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <Label className="form-label">Icone</Label>
                    <UploaderMu image={icons} setImages={setIcons} limite={1} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Button
                  isLoading={isLoading}
                  title={isUpdate ? "Modifier" : "Enregistrer"}
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </Grid>
      </Grid>
    </div>
  );
}

export default FormCathegories;
