import MarqueServices from "../services/MarqueServices";
import { notifyError, notifySuccess } from "../utils/toast";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CathegorieService from "../services/CategorieServices";
import GlobalContext from "../contexts/globalContext";

const useCategorySubmit = (selectedId) => {
  const {
    isLoading,
    setIsLoading,
    images,
    setImages,
    icons,
    setIcons,
    category,
    parentCategory,
    setParentCategory,
    setCategory,
    setSwuite,
    closeModal,
    setRefresh,
  } = React.useContext(GlobalContext);

  const submit = async (values) => {
    if (selectedId) {
      setIsLoading(true);
      const data = new FormData();
      data.append("name", values.name_Cat);
      if (images.length > 0) {
        if (images[0].originFileObj) {
          data.append("image", images[0].originFileObj);
        }
      }
      if (icons.length > 0) {
        if (icons[0].originFileObj) {
          data.append("icon", icons[0].originFileObj);
        }
      }
      if (parentCategory) {
        data.append("parent", parentCategory);
      }
      CathegorieService.updateCategory(data, selectedId.id)
        .then((res) => {
          setParentCategory(null);
          setRefresh();
          closeModal();
          notifySuccess(res.data.message);
          setIsLoading(false);
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
          setIsLoading(false);
        });
    } else {
      if (images.length === 0) {
        notifyError("l'image est obligatoire");
        setIsLoading(false);
        return;
      }
      if (icons.length === 0) {
        notifyError("l'icone est obligatoire");
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const data = new FormData();
      data.append("name", values.name_Cat);
      data.append("image", images[0].originFileObj);
      data.append("icon", icons[0].originFileObj);
      if (parentCategory) {
        data.append("parent", parentCategory);
      }
      CathegorieService.postgategories(data)
        .then((res) => {
          setIsLoading(false);
          setParentCategory(null);
          setRefresh();
          closeModal();
          notifySuccess(res.data.message);
        })
        .catch((err) => {
          notifyError(err ? err.response.data.message : err.message);
          setIsLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (selectedId) {
      setParentCategory(selectedId?.parent_id);
      setImages([
        {
          uid: "-1",
          name: selectedId?.image,
          status: "done",
          url:
            process.env.REACT_APP_URL_IMAGE + "category/" + selectedId?.image,
        },
      ]);
      setIcons([
        {
          uid: "-1",
          name: selectedId?.icon,
          status: "done",
          url: process.env.REACT_APP_URL_IMAGE + "category/" + selectedId?.icon,
        },
      ]);
    }
  }, [selectedId]);

  return {
    submit,
    isLoading,
    setIsLoading,
  };
};

export default useCategorySubmit;
