import React from "react";

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/form/TextInput";
import Inputs from "../../controller/Inputs";
import { useNavigate } from "react-router-dom";
import Button from "../../components/form/Button";
import useAdminSubmit from "../../Hooks/useAdminSubmit";
import requests from "../../services/AxiosInterceptors";
import { notifyError, notifySuccess } from "../../utils/toast";
import Header from "../../components/auth/Header";
import Footer from "../../components/auth/Footer";
import { documentTitle } from "../../utils/documentTitle";
import GlobalContext from "../../contexts/globalContext";

const RecoverPassword = () => {
  documentTitle("Recover password");
  const nav = useNavigate();
  // const { isLoading, setIsLoading } = React.useState(GlobalContext);
  const [disable, setDisable] = React.useState(false);
  const { isLoading, submit } = useAdminSubmit();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Ce champ est requis")
        .email("Veuillez entrer une adresse email valide"),
    }),
    onSubmit: (value) => {
      submit(value);
    },
  });

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="bg-overlay"></div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col lg={6} md={8} xl={4}>
              <Card className="mt-6" style={{borderRadius:30}}>
                  <CardBody className="p-4">
                    <div className="">
                      <Header
                        title={"Reset password"}
                        description={
                          "Veuillez fournir ces informations pour continuer"
                        }
                      />

                      <Row>
                        <Col md={12}>
                          {/* <div className="alert alert-warning alert-dismissible">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                            Entrez votre <b>e-mail</b> et des instructions de
                            validation vous seront envoyées !
                          </div> */}
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="mt-1">
                              <TextInput
                                type="email"
                                label={"Email"}
                                id={"email"}
                                placeholder="Enter email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email}
                                // invalid={}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                                error={validation.errors.email}
                              />
                            </div>
                            <div className="d-grid mt-4">
                              <Button
                                isLoading={isLoading}
                                // className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                title="Envoyer"
                              />
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <p className="text-white-50 text-center">
                  Avez-vous déjà un compte ?
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Connectez-vous{" "}
                  </Link>{" "}
                </p>
                <Footer />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecoverPassword;
