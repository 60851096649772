import requests from "./AxiosInterceptors"
const AdminServices = {
    loginAdmin : async (body) => {
        return requests.post('/login', body)
    },
    registerAdmin : async (body) => {
        return requests.post('/login', body)
    },
    recoverPassword : async (body) => {
        return requests.post('/ask_otp', body)
    },
    updateProfile:async (body) => {
        return requests.post('/ask_otp', body)
    }
}

export default AdminServices