import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

import { logoutUser } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Logout = () => {
  const dispatch = useDispatch();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.login.isUserLogout,
  }));

  useEffect(() => {
    try {
      localStorage.removeItem("authUser");
    } catch (error) {
     console.log(error)
    }
   // dispatch(logoutUser());
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);