import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Inputs from "../../controller/Inputs";
import { Delete, Search, Update } from "@mui/icons-material";
import DataTableCom from "../../controller/DataTableCom";
import Boutons from "../../controller/Buttonsx";
import Dialogs from "../../controller/Dialogs";
import InputCustomer from "./InputCustomer";
import { Container } from "reactstrap";

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Identite
      </Typography>
    ),
    selector: (row) => row.user,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Phone
      </Typography>
    ),
    selector: (row) => row.phone,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Email
      </Typography>
    ),
    selector: (row) => row.user,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Pays
      </Typography>
    ),
    selector: (row) => row.user,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Ville
      </Typography>
    ),
    selector: (row) => row.user,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Password
      </Typography>
    ),
    selector: (row) => row.user,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Satutus
      </Typography>
    ),
    selector: (row) => row.status,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date de la creation
      </Typography>
    ),
    selector: (row) => row.createDate,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.btn,
    sortable: true,
  },
];

function Custmone() {
  document.title = "Customer |";

  const [opendialigue, setOpenDialogue] = React.useState(false);
  const data = [
    {
      srNo: "01",
      id: "VLZ-452",
      purchaseId: "VLZ1400087402",
      title: "Post launch reminder/ post list",
      user: "Joseph Parker",
      assigned: "Alexis Clarke",
      createdBy: "Joseph Parker",
      createDate: "03 Oct, 2021",
      status: "Re-open",
      priority: "High",
      phone: "+243994296872",
      btn: (
        <Stack direction={"row"} spacing={1}>
          <IconButton>
            <Update color="info" />
          </IconButton>
          <IconButton>
            <Delete color="error" />
          </IconButton>
        </Stack>
      ),
    },
    {
      srNo: "01",
      id: "VLZ-452",
      purchaseId: "VLZ1400087402",
      title: "Post launch reminder/ post list",
      user: "Jean Marie",
      assigned: "Alexis Clarke",
      createdBy: "Joseph Parker",
      createDate: "03 Oct, 2021",
      status: "Re-open",
      priority: "High",
      phone: "+243994296872",
      btn: (
        <Stack direction={"row"} spacing={0.1}>
          <IconButton>
            <Update color="info" />
          </IconButton>
          <IconButton>
            <Delete color="error" />
          </IconButton>
        </Stack>
      ),
    },
    {
      srNo: "01",
      id: "VLZ-452",
      purchaseId: "VLZ1400087402",
      title: "Post launch reminder/ post list",
      user: "Jean Marie",
      assigned: "Alexis Clarke",
      createdBy: "Joseph Parker",
      createDate: "03 Oct, 2021",
      status: "Re-open",
      phone: "+243994296872",
      priority: "High",
      btn: (
        <Stack direction={"row"} spacing={0.1}>
          <IconButton>
            <Update color="info" />
          </IconButton>
          <IconButton>
            <Delete color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <div className="page-content">
      <Container>
        <h4
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
          }}
        >
          Custmone
        </h4>
        <div
          style={{
            padding: 20,
            background: "white",
          }}
        >
          <div
            style={{
              margin: 10,
              width: "10%",
            }}
          >
            <Boutons
              onClick={() => setOpenDialogue(!opendialigue)}
              title={"Nouveau"}
            />
          </div>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack
                sx={{
                  // width: "50%",
                  justifyContent: "space-between",
                  alignItems: "end",
                  p: 2,
                }}
                direction={"row"}
              >
                <Stack
                  sx={{
                    width: "70%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Liste entreprises
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    width: "30%",
                  }}
                >
                  <Inputs
                    placeholder="Search..."
                    icons={
                      <Search
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                      />
                    }
                  />
                </Stack>
              </Stack>
              <DataTableCom data={data} columns={column} />
            </Grid>
          </Grid>
        </div>
        <Dialogs
          title={"Nouvelle entreprise"}
          open={opendialigue}
          setOpen={setOpenDialogue}
          size="lg"
        >
          <InputCustomer />
        </Dialogs>
      </Container>
    </div>
  );
}

export default Custmone;
