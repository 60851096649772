import React from "react";

const Footer = () => {
  return (
    <div className="mt-3 text-center">
      {/* <p className="text-white-50">
      Vous n'avez pas encore de compte ?{" "}
      <Link to="/register" className="fw-medium text-primary">
        {" "}
        S'inscrire{" "}
      </Link>{" "}
    </p> */}
      <p className="text-white-50">
        © {new Date().getFullYear()} {process.env.REACT_APP_ORGANIZATION}
      </p>
    </div>
  );
};

export default Footer;
