import React from "react";
import DataTableCom from "../../controller/DataTableCom";
import {
  Close,
  Delete,
  Edit,
  EmojiObjects,
  Search,
  Update,
} from "@mui/icons-material";
import Inputs from "../../controller/Inputs";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CustomSwitch from "../../components/form/CustomSwitch";
import Button from "../../components/form/Button";
import FormCathegories from "./FormCathegories";
import StatusTableInput from "../../components/form/StatusTableInput";
import MainModal from "../../components/modals/MainModal";
import CathegorieService from "../../services/CategorieServices";
import useAsync from "../../Hooks/useAsync";
import { documentTitle } from "../../utils/documentTitle";
import SousCategoriesService from "../../services/SousCategorieService";
import { dateFormat } from "../../utils/date";
import requests from "../../services/AxiosInterceptors";
import { notifyError, notifySuccess } from "../../utils/toast";
import GlobalContext from "../../contexts/globalContext";

function Cathegories() {
  documentTitle("Cathégories");
  // const [modalIsOpen, setModalIsOpen] = React.useState(GlobalContext);
  const { data, loading: loadCategory } = useAsync(
    CathegorieService.getcategorie
  );
  const { data: datasoucat, loading: loadSubCategory } = useAsync(
    SousCategoriesService.getsouscategorie
  );
  const [dataupdate, setDataUpdate] = React.useState("");
  const [title, setTital] = React.useState("");
  const [etat, setEtat] = React.useState("");

  const [activedelete, setActiveDelet] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    modalIsOpen,
    checksuite,
    setSwuite,
    closeModal,
    handleUpdate,
    handleDelete,
    toggleModal,
    isUpdate,
  } = React.useContext(GlobalContext);


  const column = [
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Catégorie
        </Typography>
      ),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Date creation
        </Typography>
      ),
      selector: (row) => dateFormat(row.created_at),
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Status
        </Typography>
      ),
      // selector: (row) => row.date_add_cat,
      sortable: true,
      cell: (row) => {
        // console.log(row.image_cat);`http://nunuaapi.afianetrdc.com/v2/upload/${row.image_cat}}`
        // let img = "http://nunuaapi.afianetrdc.com/v2/upload/" + row.image_cat;
        return (
          <Stack direction={"row"} spacing={1}>
                 <StatusTableInput status={row.status} id={row.id} />
          </Stack>
        );
      },
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Image
        </Typography>
      ),
      // selector: (row) => row.date_add_cat,
      sortable: true,
      // button: true,
      cell: (row) => {
        // console.log(row.image_cat);`http://nunuaapi.afianetrdc.com/v2/upload/${row.image_cat}}`
        let img = process.env.REACT_APP_URL_IMAGE +'category/' + row.image
        return (
          <Stack direction={"row"} spacing={1}>
            <Avatar src={img} />
          </Stack>
        );
      },
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Actions
        </Typography>
      ),
      // selector: (row) => row.date_add_cat,
      // sortable: true,
      button: true,
      cell: (row) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <IconButton onClick={() => handleUpdate(row)}>
              <Edit color="info" />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)}>
              <Delete color="error" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  //------------------------------------

  const columnDT = [
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Sous-catégorie
        </Typography>
      ),
      selector: (row) => row.name_sous,
      sortable: true,
    },
    // {
    //   name: (
    //     <Typography
    //       sx={{
    //         fontSize: 14,
    //         fontWeight: "bold",
    //       }}
    //     >
    //       Categories
    //     </Typography>
    //   ),
    //   selector: (row) => row.date_add_cat,
    //   sortable: true,
    // },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Date creation
        </Typography>
      ),
      selector: (row) => row.date_add_sous,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Status
        </Typography>
      ),
      // selector: (row) => row.date_add_cat,
      sortable: true,
      cell: (row) => {
        // console.log(row.image_cat);`http://nunuaapi.afianetrdc.com/v2/upload/${row.image_cat}}`
        // let img = "http://nunuaapi.afianetrdc.com/v2/upload/" + row.image_cat;
        return (
          <Stack direction={"row"} spacing={1}>
                 <StatusTableInput status={row.status} id={row.id} />
          </Stack>
        );
      },
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Image
        </Typography>
      ),
      // selector: (row) => row.date_add_cat,
      sortable: true,
      // button: true,
      cell: (row) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Avatar src={process.env.REACT_APP_URL_IMAGE + row.image_souscat} />
          </Stack>
        );
      },
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Actions
        </Typography>
      ),
      // selector: (row) => row.date_add_cat,
      // sortable: true,
      button: true,
      cell: (row) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <IconButton onClick={() => handleUpdate(row)}>
              <Edit color="info" />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)}>
              <Delete color="error" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const OpenD = () => {
    setDataUpdate("");
    setTital("Création de la Catégorie");
    setEtat("save");
  };

  const ChangeTable = () => {
    setSwuite(!checksuite);
  };



  return (
    <div className="page-content">
      <div class="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
        <h4 className="text-black font-15">
          {checksuite ? "Liste des sous-catégories" : "Liste des catégories"}
        </h4>
        <Button title={"Ajouter une catégorie"} onClick={toggleModal} />
      </div>

      <div
        style={{
          padding: 20,
          background: "white",
        }}
      >
        <Grid container spacing={5}>
       
          {!checksuite ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTableCom
                loading={loadCategory}
                data={data}
                columns={column}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTableCom
                loading={loadSubCategory}
                data={datasoucat}
                columns={columnDT}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <MainModal
        title={isUpdate ? "Modifier une categorie" : "Ajouter une catégorie"}
        open={modalIsOpen}
        setOpen={closeModal}
        size="lg"
      >
        <FormCathegories etat={etat} dataupdate={dataupdate} />
      </MainModal>
    </div>
  );
}

export default Cathegories;
