import React from "react";
import Switch from "react-switch";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Non
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Oui
    </div>
  );
};

const CustomSwitch = ({ onChange, checked }) => {
  return (
    // <Switch
    //   uncheckedIcon={<Offsymbol />}
    //   checkedIcon={<OnSymbol />}
    //   className="me-1 mb-sm-8 mb-2"
    //   onColor="#626ed4"
    //   onChange={onChange}
    //   checked={checked}
    // />
    <div className="form-check form-switch form-switch-lg mb-3">
      <input
        onChange={onChange}
        checked={checked}
        type="checkbox"
        className="form-check-input"
        id="customSwitchsizelg"
        defaultChecked
      />
    </div>
  );
};

export default CustomSwitch;
