import React, { useState, useMemo, useRef } from "react";
import { Form, Label } from "reactstrap";
import TextInput from "../form/TextInput";
import UploaderMu from "../../controller/UploadImageAnted";
import useMarqueSubmit from "../../Hooks/useMarqueSubmit";
import EngagementServices from "../../services/EngagementServices";
import GlobalContext from "../../contexts/globalContext";
import useEngagementSubmit from "../../Hooks/useEngagementSubmit";
import { Select, Spin } from "antd";
import CheckBox from "../form/CheckBox";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../form/Button";
const EmployeeForm1 = ({ isUpdate, permissions, roles }) => {
  const { setImages, images, selectedId, setSearchedUser, setSelectedRole, permissions:permission, setPermissions } = React.useContext(GlobalContext);
  const { submit, isLoading } = useEngagementSubmit(selectedId);

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const validation = useFormik({
    enableReinitialize: true,

    // initialValues: {
    //   name: isUpdate ? selectedId.name_marque : "",
    // },
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Ce champs est requis"),
    // }),
    onSubmit: (values) => {
      submit(values);
    },
  });
  const handleSearch = (val) => {
    setFetching(true);
    EngagementServices.searchUser(val).then((response) => {
      setFetching(false);
      setOptions(response.data.data);
    });
  };
  function checkboxHandler(e) {
    let isSelected = e.target.checked;
    let value = e.target.value;

    if (isSelected) {
      setPermissions([...permission, value]);
    } else {
      setPermissions((prevData) => {
        return prevData.filter((id) => {
          return id !== value;
        });
      });
    }
    console.log(value)
  }
  return (
    <div>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          submit()
          return false;
        }}
      >
        {/* <div className="row">
          <div className="col-lg-6 form-group">
            <TextInput
              label="Nom"
              name="name"
              id="validationCustom02"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
              error={validation.errors.name}
            />
          </div>
          <div className="col-lg-6 form-group">
            <TextInput
              label="Post-nom"
              name="name"
              id="validationCustom02"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
              error={validation.errors.name}
            />
          </div>
          <div className="col-lg-6 form-group">
            <TextInput
              label="Email"
              type="email"
              name="name"
              id="validationCustom02"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
              error={validation.errors.name}
            />
          </div>
          <div className="col-lg-6 form-group">
            <TextInput
              label="Phone"
              name="name"
              id="validationCustom02"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
              error={validation.errors.name}
            />
          </div>
        </div> */}
        <div className="row">
          <div className="form-group">
          <Label>Utilisateur</Label>
            <Select
              showSearch
              loading={fetching}
              placeholder="Recherche"
              optionFilterProp="children"
              style={{ width: "100%" }}
              size="large"
              onChange={(val) => setSearchedUser(val)}
              onSearch={handleSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={options?.map((item) => {
                return {
                  label: item.full_name ? item.full_name : item.email,
                  value: item.id,
                };
              })}
            />
          </div>
          <div className="form-group">
          <Label>Role</Label>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              size="large"
              onChange={(val) => setSelectedRole(val)}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={roles?.map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
            />
          </div>
        </div>
        <div className="row">
          <Label>Permissions</Label>
          {permissions?.map((item, i) => (
            <div className="col-lg-4 col-sx-6 col-sm-6 col-md-6 col-6 form-group">
              <CheckBox key={item.id} 
              label={item.name} id={item.id} 
              checked={permission.includes(item.id)}
              value={item.id}
              onChange={checkboxHandler}
              />
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-lg-8" />
          <div className="col-lg-4 form-group">
            <Button
              isLoading={isLoading}
              title={isUpdate ? "Modifier" : "Enregistrer"}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EmployeeForm1;
