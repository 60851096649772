import { Grid } from "@mui/material";
import React from "react";
import { Form, Label } from "reactstrap";
import TextInput from "../../components/form/TextInput";
import Button from "../../components/form/Button";
import GlobalContext from "../../contexts/globalContext";
import useRoleSubmit from "../../Hooks/useRoleSubmit";
import { useFormik } from "formik";
import * as Yup from "yup";

function FormPermission({ isUpdate }) {
  const { selectedId } = React.useContext(GlobalContext);
  const { submit, isLoading } = useRoleSubmit(selectedId);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: isUpdate ? selectedId.name : "",
      pseudo: isUpdate ? selectedId.pseudo : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Ce champs est requis"),
      pseudo: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      submit(values);
    },
  });
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <TextInput
            label="Nom de la permission"
            name="name"
            id="validationCustom02"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
            error={validation.errors.name}
          />
          <TextInput
            label="Pseudo"
            name="pseudo"
            id="validationCustom02"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.pseudo}
            invalid={
              validation.touched.pseudo && validation.errors.pseudo
                ? true
                : false
            }
            error={validation.errors.pseudo}
          />
          <Button type="submit" isLoading={isLoading} title={"Enregistrer"} />
        </Form>
      </Grid>
    </Grid>
  );
}

export default FormPermission;
