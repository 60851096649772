import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
// import ProductServices from "services/ProductServices";
import { Upload } from "antd";
// import { SidebarContext } from "context/SidebarContext";

const UploaderMu = ({ setImages, limite, id, image }) => {
  // const { image } = useContext(SidebarContext);
  const location = useLocation();
  const handleChange = ({ fileList: newFileList }) => {
    setImages(newFileList);
  };

  const uploadButton = (
    <div className="text-md text-gray-700 dark:text-gray-500">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Télécharger
      </div>
    </div>
  );

  // const onRemove = (e) => {
  //   if (id) {
  //     if (location.pathname === "/products") {
  //       try {
  //         ProductServices.deleteProductImage(id, e.name);
  //       } catch (er) {
  //         console.log(er);
  //       }
  //     }
  //   }
  // };

  return (
    <div>
      <Upload
        // onRemove={onRemove}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        accept="image/png,image/jpeg"
        fileList={image}
        multiple={location.pathname === "/products" ? true : false}
        onChange={handleChange}
        maxCount={limite}
      >
        {image.length >= limite ? null : uploadButton}
      </Upload>
    </div>
  );
};

export default UploaderMu;
