import React, { useContext } from "react";
import Routes from "./Routes/index";
import { ToastContainer } from "./utils/toast";
// Import Scss
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NoConnection from "./Pages/NoConnection";
import "react-image-gallery/styles/css/image-gallery.css";
import "./assets/scss/theme.scss";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import GlobalContext from "./contexts/globalContext";

// Activating fake backend
//fakeBackend();

// Firebase
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

function App() {
  const { isOnline } = useContext(GlobalContext);
  if (!isOnline) {
    return <NoConnection />;
  }
  return (
    <React.Fragment>
        <Routes />
        <ToastContainer />
    </React.Fragment>
  );
}

export default App;
