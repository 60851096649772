import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import DataTableCom from "../../controller/DataTableCom";
import { Edit, Delete } from "@mui/icons-material";
import GlobalContext from "../../contexts/globalContext";
import Inputs from "../../controller/Inputs";
import StatusTableInput from "../../components/form/StatusTableInput";
import { dateFormat } from "../../utils/date";
import useAsync from "../../Hooks/useAsync";
import { documentTitle } from "../../utils/documentTitle";
import Dialogs from "../../controller/Dialogs";

import ImageGallery from "react-image-gallery";
import MainDrawer from "../../components/modals/MainDrawer";
import Button from "../../components/form/Button";
import FormProduct from "../../components/products/InputProduit";
import { useLocation, useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import ProductServices from "../../services/ProductServices";
import { Badge } from "reactstrap";

function DetailProduit() {
  const location = useLocation();
  const search = location.search;
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  if (!query.get("id")) {
    navigate("/", { replace: true });
  }
  const {
    modalIsOpen,
    closeModal,
    toggleModal,
    isUpdate,
    handleUpdate,
    handleDelete,
    companyInfo,
  } = useContext(GlobalContext);
  const { data: produits, loading } = useAsync(() =>
    ProductServices.oneProduct(query.get("id"))
  );
  documentTitle(produits?.Detail_Product?.name_produit || "Details du produit");

  return (
    <div className="page-content">
      {loading ? (
        <div
          style={{
            padding: 20,
            background: "white",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="mx-auto col-10 col-md-8 col-lg-6">
            <ScaleLoader
              color="#44cef5"
              loading={true}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      ) : (
        <>
          <div class="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
            <h4 className="text-black font-15">
              {produits?.Detail_Product?.name_produit}
            </h4>
          </div>
          <div
            style={{
              padding: 20,
              background: "white",
            }}
          >
            <div className="row">
              <div className="col-lg-4 col-md-4">
                {/* <img
              style={{ width: "100%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg-3382ZgdUhzsOz0VYE8KVNtX_HTwTxRSps08Nli1&s"
              alt="..."
              class="img-thumbnail"
            /> */}
                <ImageGallery
                  originalHeight={400}
                  sizes={"large"}
                  items={produits?.ImageProduit?.map((item) => {
                    return {
                      original:
                        process.env.REACT_APP_URL_IMAGE +
                        "products/" +
                        item.images,
                      thumbnail:
                        process.env.REACT_APP_URL_IMAGE +
                        "products/" +
                        item.images,
                    };
                  })}
                />
              </div>
              <div className="col-lg-8 col-mg-8">
                <h4>{produits?.Detail_Product?.name_produit}</h4>
                <div className="mt-2">
                  <span>
                    <strong>Status : </strong>
                    {produits?.Detail_Product?.status === 1 ? (
                      <Badge color="success">Publié</Badge>
                    ) : (
                      <Badge color="warning">Masqué</Badge>
                    )}
                  </span>
                </div>
                <div className="mt-2">
                  <span>
                    <strong>Etat stock : </strong>
                    {produits?.Detail_Product?.qte >= 1 ? (
                      <Badge color="success">Disponible</Badge>
                    ) : (
                      <Badge color="warning">En rupture de stock</Badge>
                    )}
                  </span>
                </div>
                <div className="mt-2 mb-2">
                  <span>
                    <strong>
                      Quantité : {produits?.Detail_Product?.qte}{" "}
                      {produits?.Detail_Product?.mesure}
                    </strong>
                  </span>
                </div>
                {produits?.Detail_Product?.description}
                <div className="mt-5">
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <span style={{ marginRight: 10, marginBottom: 10 }}>
                      Publier ou masquer le produit
                    </span>
                    <Stack direction={"row"} spacing={1}>
                      <StatusTableInput
                        status={produits?.Detail_Product?.status}
                        id={query.get("id")}
                      />
                    </Stack>
                  </div>

                  <Button
                    title="Modifier le produit"
                    onClick={() => handleUpdate(produits?.Detail_Product)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <MainDrawer
        title={isUpdate ? "Modifier un produit" : "Créer un produit"}
        open={modalIsOpen}
        setOpen={closeModal}
        size="lg"
      >
        <FormProduct />
      </MainDrawer>
    </div>
  );
}

export default DetailProduit;
