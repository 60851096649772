import { Grid, IconButton, Stack, Switch, Typography } from "@mui/material";
import React, {useContext} from "react";
import { documentTitle } from "../../utils/documentTitle";
import StatusTableInput from "../../components/form/StatusTableInput";
import MesureServices from "../../services/MesureServices";
import GlobalContext from "../../contexts/globalContext";
import MainModal from "../../components/modals/MainModal";
import FormUnitDeMesure from "./FormUnitDeMesure";
import useAsync from "../../Hooks/useAsync";
import Button from "../../components/form/Button";
import DataTableCom from "../../controller/DataTableCom";
import { Delete, Edit } from "@mui/icons-material";



export default function UniteDeMesure() {
  documentTitle("Unite de mesure");
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const {toggleModal,closeModal,companyInfo, isUpdate,modalIsOpen, setModalIsOpen, handleDelete, handleUpdate} = useContext(GlobalContext)
  const { data: mesures, loading } = useAsync(() => MesureServices.getMesures(companyInfo.id_entrep));

  const column = [
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Unite de mesure
        </Typography>
      ),
      selector: (row) => row.name,
      sortable: true,
    },
  
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Satutus
        </Typography>
      ),
      // selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        // console.log(row.image_cat);`http://nunuaapi.afianetrdc.com/v2/upload/${row.image_cat}}`
        // let img = "http://nunuaapi.afianetrdc.com/v2/upload/" + row.image_cat;
        return (
          <Stack direction={"row"} spacing={1}>
           <StatusTableInput status={row.status} id={row.id}/>
          </Stack>
        );
      },
    },  
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Action
        </Typography>
      ),
      button: true,
      cell: (row) => (
        <Stack direction={"row"} spacing={1}>
          <IconButton onClick={() => handleUpdate(row)}>
            <Edit color="info" />
          </IconButton>
          <IconButton onClick={() => handleDelete(row)}>
            <Delete color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];



  return (
    <div className="page-content">
       <div className="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
        <h4 className="text-black font-15">
          Unités de mésure
        </h4>
        <Button title={"Ajouter une unité"} onClick={toggleModal} />
      </div>
      <Grid direction={"column"} container bgcolor={"#FFF"} p={2}>
        <Grid item xs={12} md={12} sm={12} lg={12} mt={5}>
          <DataTableCom loading={loading} data={mesures} columns={column} />
        </Grid>
      </Grid>
      <MainModal
        title={isUpdate ? "Modifier une unité" : "Ajouter une unité"}
        open={modalIsOpen}
        setOpen={closeModal}
        size="md"
      >
        <FormUnitDeMesure isUpdate={isUpdate} />
      </MainModal>
    </div>
  );
}
