import React from "react";
import DataTable from "react-data-table-component";
import ScaleLoader  from "react-spinners/ScaleLoader";
import { Empty } from 'antd';
function DataTableCom(props) {
  return (
    <DataTable
      progressPending={props.loading}
      style={{height:'100%'}}
      progressComponent={
        <ScaleLoader
        color="#44cef5"
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      }
      noDataComponent={<Empty description="Aucune donnée"/>}
      fixedHeaderScrollHeight="300px"
      columns={props.columns}
      data={props.data}
      pagination
    />
  );
}

export default DataTableCom;
