import React from "react";
import CustomSwitch from "./CustomSwitch";
import { useLocation } from "react-router-dom";
import ProductServices from "../../services/ProductServices";
import GlobalContext from "../../contexts/globalContext";
import EngagementServices from "../../services/EngagementServices";
import { notifyError, notifySuccess } from "../../utils/toast";
const StatusTableInput = ({ status, id }) => {
  const location = useLocation();
  const [checked, setChecked] = React.useState(status === 1 ? true : false);
  const { setRefresh } = React.useContext(GlobalContext);
  const handleChangeStatus = async (id) => {
    try {
      let newStatus;
      if (status === 1) {
        newStatus = 0;
        setChecked(false);
      } else {
        newStatus = 1;
        setChecked(true);
      }

      if (location.pathname === "/products") {
        const res = await ProductServices.updateStatus({
          id_produit: id,
          status: newStatus,
        });
        setRefresh();
        notifySuccess(res.data.message);
      }

      if (location.pathname === "/companies") {
        const res = await EngagementServices.validateEntreprise({
          id_entreprise: id,
          status: newStatus,
        });
        setRefresh();
        notifySuccess(res.data.message);
      }
      if (location.pathname === "/product") {
        const res = await ProductServices.updateStatus({
          id_produit: id,
          status: newStatus,
        });
        setRefresh();
        notifySuccess(res.data.message);
      }
    } catch (err) {
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };
  return (
    <CustomSwitch checked={checked} onChange={() => handleChangeStatus(id)} />
  );
};

export default StatusTableInput;
