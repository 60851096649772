import React from "react";
import {
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
const TextInput = ({
  label,
  onChange,
  onBlur,
  type,
  invalid,
  error,
  name,
  row,
  disabled,
  placeholder,
  id,
  value,
}) => {
  return (
    <div className="mb-2">
      <Label className="form-label">{label}</Label>
      <Input
      disabled={disabled}
        name={name}
        placeholder={placeholder}
        id={id}
        type={type}

        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
      />
      {error ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
    </div>
  );
};

export default TextInput;
