import MarqueServices from "../services/MarqueServices";
import { notifyError, notifySuccess } from "../utils/toast";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "../contexts/globalContext";

const useMarqueSubmit = (selectedId) => {
  const { isLoading, setIsLoading, images, setImages, closeModal, setRefresh } =
    React.useContext(GlobalContext);

  const submit = async ({ name }) => {
    // console.log(location);

    try {
      const formdata = new FormData();
      if (selectedId) {
        setIsLoading(true);
        formdata.append("name", name);
        if (images.length > 0) {
          if (images[0].originFileObj) {
            formdata.append("image", images[0].originFileObj);
          }
        }
        const res = await MarqueServices.updateMarque(formdata, selectedId.id);
        if (res.status === 200) {
          setRefresh();
          closeModal();
          notifySuccess("Marque modifiée avec succès");
        } else {
          notifyError(res.data.message);
        }
        setIsLoading(false);
      } else {
        if (images.length > 0) {
          setIsLoading(true);
          formdata.append("name", name);
          formdata.append("image", images[0].originFileObj);
          const res = await MarqueServices.createMarque(formdata);
          if (res.status === 200) {
            setRefresh();
            closeModal();
            notifySuccess("Marque créée avec succès");
          } else {
            notifyError(res.data.message);
          }
          setIsLoading(false);
        } else {
          notifyError("Please upload an image");
        }
      }
    } catch (err) {
      notifyError(err ? err.response.data.message : err.message);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if(selectedId){
      setImages([
        {
          uid: "-1",
          name: selectedId.image,
          status: "done",
          url:
            process.env.REACT_APP_URL_IMAGE + 'marques/' + selectedId.image
        },
      ]);
    }
  }, [selectedId]);

  return {
    submit,
    isLoading,
  };
};

export default useMarqueSubmit;
