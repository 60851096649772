import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 50000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },

});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token;
  if (localStorage.getItem('userToken')) {
    token = JSON.parse(localStorage.getItem('userToken'));
  }


  // console.log('Admin Http Services Cookie Read : ' + company);
  // let companyName = JSON.stringify(company);

  return {
    ...config,
    headers: {
      authorization: token ? `Bearer ${token}` : null
    },
  
    
  };
});
instance.interceptors.response.use((response) => response, (error) => {
  // not a 401, simply fail the response
  return Promise.reject(error);
});

const responseBody = (response) => response;
const catchError = (error) => error;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody).catch(catchError),

  post: (url, body,headers) => instance({
    method:'post',
    url:process.env.REACT_APP_API_BASE_URL + url,
    data:body,
    withCredentials:false,
    crossdomain:true,
    headers,
    mode:'no-cors'
  }),

  put: (url, body, headers) =>
    instance.put(url, body, headers),

  patch: (url, body, headers) => instance.patch(url, body, headers).then(responseBody).catch(catchError),

  delete: (url, body, headers) => instance.delete(url, body, headers).then(responseBody),
};

export default requests;
