import React from "react";
import { Form, Label } from "reactstrap";
import TextInput from "../form/TextInput";
import UploaderMu from "../../controller/UploadImageAnted";
import useMarqueSubmit from "../../Hooks/useMarqueSubmit";
import GlobalContext from "../../contexts/globalContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../form/Button";
const MarqueForm = ({ isUpdate }) => {
  const { setImages, images, selectedId } = React.useContext(GlobalContext);
  const {submit, isLoading} = useMarqueSubmit(selectedId)
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: isUpdate ? selectedId.name : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Ce champs est requis"),
    }),
    onSubmit: (values) => {
      submit(values);
    },
  });
  return (
    <div>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <TextInput
          
          label="Nom de la marque"
          name="name"
          id="validationCustom02"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.name}
          invalid={
            validation.touched.name && validation.errors.name ? true : false
          }
          error={validation.errors.name}
        />
        <Label>Image</Label>
        <UploaderMu image={images} setImages={setImages} limite={1} />
        <div className="row">
          <div className="col-lg-4">
          <Button isLoading={isLoading} title={isUpdate ? "Modifier" : "Enregistrer"} />
          </div>
          
        </div>
      </Form>
    </div>
  );
};

export default MarqueForm;
