import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Spinner } from "reactstrap";
import React, { useState, useContext } from "react";
import Button from "../../components/form/Button";
import GlobalContext from "../../contexts/globalContext";
import { Badge } from "reactstrap";
import { documentTitle } from "../../utils/documentTitle";
import SettingServices from "../../services/SettingServices";
import { notifyError } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import useAsync from "../../Hooks/useAsync";
// import { Col, Container, Row } from "reactstrap";

function CardEntreprise() {
  documentTitle("Choix de l'entreprise");
  const { setCompanyInfo, setIsAdmin } = useContext(GlobalContext);
  const { data, loading } = useAsync(() => SettingServices.allCompanies());
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const storeEntreprise = (item) => {
    if (item?.entreprise?.status === 1) {
      setCompanyInfo(item);
      localStorage.setItem("company_informations", JSON.stringify(item));
      navigate("/", { replace: true });
    } else {
      notifyError(
        "Cette entreprise n'est pas validée, veuillez contacter l'administrateur"
      );
    }
  };

  const adminMode = () => {
    setIsAdmin(true);
    localStorage.setItem("isAdmin", true);
  };

  return (
    <div className="page-content">
      <div class="container-fluid d-flex p-4 bg-white mb-2 align-items-xl-center flex-xl-row justify-content-xl-between flex-column">
        <p className="text-black font-15">
          Veuillez choisir une entreprise pour continuer
        </p>
        <Button onClick={adminMode} title={"Continuer en tant qu'administrateur"} />
      </div>
      <Grid
        sx={{
          background: "",
          borderRadius: 2,
        }}
        container
        padding={2}
        mt={2}
      >
        {data.map((item) => {
          return (
            <Card
              key={item.id}
              onClick={() => {
                setSelected(item);
                storeEntreprise(item);
              }}
              role="button"
              sx={{
                m: 1,
                // p: 1,
              }}
            >
              <CardHeader
                // avatar={
                //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                //     R
                //   </Avatar>
                // }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title={item.entreprise.name}
                subheader={
                  <span>
                    Status:{" "}
                    {item.entreprise.status ===  1? (
                      <Badge color="success">Validée</Badge>
                    ) : (
                      <Badge color="warning">En attente</Badge>
                    )}
                  </span>
                }
              />
              <div className="w-100 position-relative">
                <CardMedia
                  component="img"
                  height="194"
                  image={
                    process.env.REACT_APP_URL_IMAGE +
                    "entreprises/" +
                    item.entreprise.logo
                  }
                />
                {isLoading && selected.entreprise.id === item.entreprise.id ? (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Spinner size="2xl" color="primary" />
                  </div>
                ) : null}
              </div>

              {/* <CardContent>
                <Typography variant="body2">{e.cat}</Typography>
              </CardContent> */}
            </Card>
          );
        })}
      </Grid>
    </div>
  );
}

export default CardEntreprise;
