import React from "react";
import Select from "react-select";
import { Input, FormFeedback, Label } from "reactstrap";
const SelectTwo = ({
  label,
  onChange,
  onBlur,
  type,
  invalid,
  data,
  error,
  name,
  placeholder,
  id,
  value,
}) => {
  return (
    <>
    <div className="mb-2">
      <Label className="form-label">{label}</Label>
      
      <Select
        value={value}
        key={value}
        onChange={(val) => onChange(val)}
        options={data}
        name={name}
        id={id}
        onBlur={onBlur}
        invalid={invalid}
        classNamePrefix="select2-selection"
      />
      {error ? <small className="text-danger">{error}</small>:null}
     
    </div>
     
     </>
  );
};

export default SelectTwo;
