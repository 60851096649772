import requests from "./AxiosInterceptors";

const EngagementServices = {
  getTeam: async (id) => {
    return requests.get("/engagement/" + id);
  },
  getAllEntreprise: async () => {
    return requests.get("/allEntreprises");
  },
  validateEntreprise: async (body) => {
    return requests.put("/validate-entreprise", body);
  },
  searchUser: async (query) => {
    return requests.get(`/search-user/${query}`);
  },
  createFromCloud: async (body) => {
    return requests.post("/engagement", body);
  },
  deleteMesure: async (entreprise, id) => {
    return requests.delete(`/entreprise/mesure/${entreprise}/${id}`);
  },
  addSubCategory: async (body) => {
    return requests.post("/createsouscat", body);
  },
  updateMesure: async (body, entreprise, id) => {
    return requests.put(`/entreprise/mesure/${entreprise}/${id}`, body);
  },
  updateSubCategory: async (body) => {
    return requests.post("/updatesouscatgory", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default EngagementServices;
