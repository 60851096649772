import requests from "./AxiosInterceptors";

const MesureServices = {
  getMesures: async (id) => {
    return requests.get("/mesures/"+id);
  },
  categoryTree: async () => {
    return requests.get("/listcat");
  },
  createMesure: async (body,entreprise) => {
    return requests.post("/entreprise/mesure/"+entreprise, body);
  },
  deleteMesure: async (entreprise, id) => {
    return requests.delete(`/entreprise/mesure/${entreprise}/${id}`);
  },
  addSubCategory: async (body) => {
    return requests.post("/createsouscat", body);
  },
  updateMesure: async (body,entreprise, id) => {
    return requests.put(`/entreprise/mesure/${entreprise}/${id}`, body);
  },
  updateSubCategory: async (body) => {
    return requests.post("/updatesouscatgory", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default MesureServices;
