// import { Person } from "@mui/icons-material";
// import { AccountCircle } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  // TextField,
} from "@mui/material";
// import { Stack } from "@mui/system";
import React from "react";

function Inputs(props) {
  return (
    <Box
      width={"100%"}
      // border={2}
      // borderColor={"GrayText"}
      // borderRadius={2}
      sx={{ display: "flex", alignItems: "flex-end" }}
    >
      {
        //props.icons
      }
      <OutlinedInput
        id={props.id}
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "#FFF",
          marginTop: 1,
          // color: "#FFF",
          mt: 2,
        }}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        size="small"
        multiline={props.multiline}
        rows={5}
        // variant="outlined"
        type={props.type}
        placeholder={props.placeholder}
        endAdornment={
          props.visi === true ? (
            <InputAdornment position="end">
              <IconButton>
                <Visibility
                  sx={{
                    color: "#192C56",
                  }}
                />
              </IconButton>
            </InputAdornment>
          ) : (
            ""
          )
        }
        onBlur={props.onBlur}
        {...(props.error && { error: true, heelperText: props.error })}
      />
    </Box>
  );
}

export default Inputs;
