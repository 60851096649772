import React from "react";

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { documentTitle } from "../../utils/documentTitle";
import Header from "../../components/auth/Header";
import Footer from "../../components/auth/Footer";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import TextInput from "../../components/form/TextInput";
import * as Yup from "yup";
import Button from "../../components/form/Button";
import requests from "../../services/AxiosInterceptors";
import { notifyError, notifySuccess } from "../../utils/toast";
import GlobalContext from "../../contexts/globalContext";

const ChangePassWord = () => {
  documentTitle("Nouveau mot de passe");

  const nav = useNavigate();
  const emailx = localStorage.getItem("email");
  const [disable, setDisable] = React.useState(false);
  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      pswd: "",
      pswd_confirm: "",
      email: emailx,
    },
    validationSchema: Yup.object({
      pswd: Yup.string()
        .required("Ce champs est requis")
        .matches(
          passwordRules,
          "Votre mot de passe doit inclure les caractères, majuscule, miniscule, chifres"
        ),
      pswd_confirm: Yup.string()
        .required("Ce champs est requis")
        .oneOf(
          [Yup.ref("pswd"), null],
          "Les deux mots de passe doivent correspondre"
        ),
    }),
    onSubmit: (value) => {
      // console.log(value);
      setDisable(true);
      requests
        .post("/changePswd", value)
        .then((e) => {
          if (e.status === 200) {
            notifySuccess(e.data.message);
            nav("/login", { replace: true });
            setDisable(false);
          } else {
            notifyError(e.data.message);
            setDisable(false);
          }
        })
        .catch((error) => {
          notifyError(error.response.data.message);
          setDisable(false);
        });
    },
  });

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="bg-overlay"></div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <Header />
                      <div className="alert alert-warning alert-dismissible">
                        Entrez ces informations pour terminer la
                        réinitialisation de votre mot de passe
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <div className="mt-4">
                              <TextInput
                                type="password"
                                className="form-control"
                                id={"pswd"}
                                label="Nouveau Mot de passe"
                                placeholder="Nouveau Mot de passe"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pswd}
                                error={validation.errors.pswd}
                                invalid={
                                  validation.touched.pswd &&
                                  validation.errors.pswd
                                    ? true
                                    : false
                                }
               
                              />
                            </div>
                            <div className="mt-4">
                              <TextInput
                                type="password"
                                className="form-control"
                                id={"pswd_confirm"}
                                placeholder="Confirmation du mot de passe"
                                label="Confirmation du mot de passe"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pswd_confirm}
                                error={validation.errors.pswd_confirm}
                                invalid={
                                  validation.touched.pswd_confirm &&
                                  validation.errors.pswd_confirm
                                    ? true
                                    : false
                                }
                            
                              />
                            </div>
                            <div className="d-grid mt-4">
                              <Button
                                type="submit"
                                title="Enregistrer"
                                isLoading={disable}
                              />
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
                <p className="text-white-50 text-center">
                  Avez-vous déjà un compte ?
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Connectez-vous{" "}
                  </Link>{" "}
                </p>
                <Footer />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePassWord;
